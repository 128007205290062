import './DevicePage.css';
import Layout from '../../components/Layout/Layout';
import { Spinner } from 'react-bootstrap';

const DevicePage = () => {
  return (
    <Layout>
      <div className='devicePage-style'>
        <div className='device-body'>
          <div className="container">
            <div className="content">
              <h1>Oops!</h1>
              <Spinner />
              <p>Al parecer estás ingresando desde un dispositivo no soportado por el área de Administración.</p>
              <p>Recordá que a la Administración solo es posible ingresar a través de una Computadora.</p>
              <p>Necesitas Ayuda? Contactanos a <a href="mailto:support@example.com">marketar.arg@gmail.com</a></p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DevicePage;