import axiosClient from "../../settings/axiosClient";
import { POST_USER, REMOVE_USER } from "../../type";

export default (state, action) => {
  switch(action.type){
    case POST_USER:
      return {
        ...state,
        auth: true,
        sign: action.payload.sign,
        admin: action.payload.admin
      }
    
    case REMOVE_USER:
      if(localStorage.getItem('ID')){
        localStorage.removeItem('ID')
      }
      delete axiosClient.defaults.headers.common['x-auth-token'];
      return {
        ...state,
        auth: false,
        admin: false,
        sign: ''
      }
  }
}