import { faBroom, faRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

const StockViewRow = ({ entry, updatePrice, entrySelected, setEntrySelected, index }) => {
  const [itemSalesPrice, setItemSalesPrice] = useState(entry.salesPricePerUnit.toFixed(2));
  const [realTimeProfit, setRealTimeProfit] = useState(0);
  const profit = Math.round(Number(entry.salesPricePerUnit/(entry.wholePrice/(entry.wholeQuantity-entry.waste))-1)*10000)/100;
  const [priceEdition, setPriceEdition] = useState(false);

  const handleChange = (e) => {
    setItemSalesPrice(e.target.value);
    const profit = Math.round(Number(e.target.value/(entry.wholePrice/(entry.wholeQuantity-entry.waste))-1)*10000)/100;
    setRealTimeProfit(profit.toFixed(2));
  }

  const handleKeyUp = (e) => {
    if((e.keyCode === 13 || e.keyCode === 9) && entry.salesPricePerUnit !== Number(itemSalesPrice)){
      e.preventDefault();
      const confirm = window.confirm('¿Está seguro que desea actualizar el precio de venta?');
      if(confirm) updatePrice(entry._id, itemSalesPrice);
    }
    if(e.keyCode === 27){
      setEntrySelected(-1);
    }
    if(e.keyCode === 9 && entry.salesPricePerUnit === Number(itemSalesPrice)){
      e.preventDefault();
      setEntrySelected(index+1);
    }
  }

  const editRowPrice = () => {
    setEntrySelected(index);
  }
  
  useEffect(() => {
    if(!priceEdition){
      setItemSalesPrice(entry.salesPricePerUnit.toFixed(2));
      setRealTimeProfit(profit.toFixed(2));
    }
  }, [priceEdition])

  useEffect(() => {
    setRealTimeProfit(profit.toFixed(2));
  }, [])

  useEffect(() => {
    if(entrySelected === index && entrySelected !== -1){
      setPriceEdition(true)
    }else{
      setPriceEdition(false);
    } 
  }, [entrySelected])

  return (
    <>
      <tr>
        <td className={entrySelected === index ? "bg-selected" : null}>{entry.createdAt.substring(0, 10)} {Number(entry.createdAt.substring(11, 13)-3)+entry.createdAt.substring(13, 16)}hs</td>
        <td className={entrySelected === index ? "bg-selected" : null}>{entry.itemName}</td>
        <td className={`text-center ${entrySelected === index ? "bg-selected" : null}`}>{entry.wholeQuantity + entry.unit}</td>
        <td className={`text-center ${entrySelected === index ? "bg-selected" : null}`}>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(Number(entry.wholePrice))}</td>
        <td className={`text-center ${entrySelected === index ? "bg-selected" : null}`}>{entry.waste.toFixed(1) + entry.unit}</td>
        <td className={`text-center ${entrySelected === index ? "bg-selected" : null}`}>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(Number(entry.wholePrice/(entry.wholeQuantity-entry.waste)))}</td>
        <td className={`text-center ${entrySelected === index ? "bg-selected" : null}`}>{entry.wholeQuantityUpdated.toFixed(2) + entry.unit}</td>
        <td className={`wpx-150 hpx-60 p-0 ${priceEdition && entry.wholeQuantity > 0 ? null : "d-none"}`}>
          <div className="d-flex align-items-center justify-content-center h-100 input-group">
            <span className="fw-bold h-100 input-group-text prefix-noborder">$</span>
            <input autoComplete="off" id={`ura-${index}`} required className="form-control wpx-100 input-noborder" type="number" step=".1" value={itemSalesPrice} onKeyDown={handleKeyUp} onChange={handleChange} onFocus={(e) => e.target.select()} onBlur={(e) => setItemSalesPrice(Number(e.target.value).toFixed(2))} />
          </div>
        </td>
        <td className={`hpx-60 p-0 wpx-100 ${priceEdition && entry.wholeQuantity > 0 ? null : "d-none"}`}>
          <div className="d-flex align-items-center justify-content-center h-100">
            <input autoComplete="off" className={`form-control wpx-80 text-center input-noborder ${realTimeProfit > profit ? "text-success" : (realTimeProfit < profit ? "text-danger" : null)}`} type="text" value={realTimeProfit < 0 ? "-" : realTimeProfit+"%"} readOnly disabled />
          </div>
        </td>
        <td className={`text-center wpx-150 hpx-60 pointer ${priceEdition && entry.wholeQuantity > 0 ? "d-none" : null}`} onClick={editRowPrice}>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(Number(entry.salesPricePerUnit))}</td>
        <td className={`text-center wpx-100 ${priceEdition && entry.wholeQuantity > 0 ? "d-none" : null}`}>{realTimeProfit + "%"}</td>
        <td className={`wpx-35 ${entrySelected === index ? "bg-selected" : null}`}>
          <span className={`pointer rotate-effect ${entry.salesPricePerUnit !== Number(itemSalesPrice) ? null : "d-none"}`} id={`priceUpdateBtn-${index}`} onClick={priceEdition ? () => updatePrice(entry._id, itemSalesPrice) : null}><FontAwesomeIcon icon={faRotate} /></span>
        </td>
        <td className={`wpx-35 ${entrySelected === index ? "bg-selected" : null}`}>
          {
            priceEdition ? (
              <span className="pointer broom-effect" onClick={() => setEntrySelected(-1)}><FontAwesomeIcon icon={faBroom} /></span>
            ) : null
          }
        </td>
      </tr>
    </>
  );
};

export default StockViewRow;