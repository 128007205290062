import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import ProductsContext from "../../context/Products/ProductsContext";

const StockCompressedRow = ({item, stockShowed, compressAll, setCompressAll}) => {
  const [decompress, setDecompress] = useState(false);
  const [decompressedData, setDecompressedData] = useState([]);
  const { stock } = useContext(ProductsContext);

  useEffect(() => {
    if(stock.length !== 0){
      let dataArray = [];
      stock.forEach(entry => {
        if(entry.itemId === item.itemId && entry.published){
          dataArray.push(entry);
        }
      })
      setDecompressedData(dataArray);
    }
  }, [stockShowed, stock])

  useEffect(() => {
    if(compressAll){
      setDecompress(false);
      setCompressAll(false);
    }
  }, [compressAll])

  return (
    <>
      <tr>
        <td className="text-center"><FontAwesomeIcon className={`pointer ${decompress ? "text-warning" : null}`} icon={decompress ? faAngleUp : faAngleDown} onClick={() => setDecompress(!decompress)} /></td>
        <td className={!item.lastEntryPublished ? "text-warning" : (item.flag1Warn ? "text-danger" : null)}>{item.itemName}</td>
        <td className={`text-center ${!item.lastEntryPublished ? "text-warning" : (item.flag1Warn ? "text-danger" : null)}`}>{Number(item.quantity) !== 0 ? item.quantity + (item.differentUnits ? " U.D." : item.unit) : "-"}</td>
        <td className={`text-center ${!item.lastEntryPublished ? "text-warning" : (item.flag1Warn ? "text-danger" : null)}`}>{item.actualCost !== 0 ? new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(Number(item.actualCost))+ "/" + item.unit : "-"}</td>
        <td className={`text-center ${!item.lastEntryPublished ? "text-warning" : (item.flag1Warn ? "text-danger" : null)}`}>{item.actualPrice !== 0 ? new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(Number(item.actualPrice))+ "/" + item.unit : "-"}</td>
        <td className="text-center wpx-180"></td>
      </tr>
      {
        decompressedData?.map((entry, index) => (
          <tr key={index} className={`${!decompress ? "d-none" : null} ${entry.wholeQuantityUpdated === 0 ? "text-danger" : null}`}>
            <td className="text-center bg-decompress"></td>
            <td className="bg-decompress"></td>
            <td className={`text-center bg-decompress ${entry.wholeQuantityUpdated === 0 ? "text-danger" : null}`}>{entry.wholeQuantityUpdated.toFixed(2) + entry.unit}</td>
            <td className={`text-center bg-decompress ${entry.wholeQuantityUpdated === 0 ? "text-danger" : null} ${item.quantity !== 0 && (decompressedData.filter(item => item.wholeQuantityUpdated !== 0).length -1) === index ? "text-primary" : null}`}>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(Number(entry.wholePrice/entry.wholeQuantity))+ "/" +entry.unit}</td>
            <td className={`text-center bg-decompress ${entry.wholeQuantityUpdated === 0 ? "text-danger" : null} ${item.quantity !== 0 && (decompressedData.filter(item => item.wholeQuantityUpdated !== 0).length -1) === index ? "text-primary" : null}`}>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(Number(entry.salesPricePerUnit))+ "/" +entry.unit}</td>
            <td className="text-center bg-decompress wpx-180">{entry.createdAt.substring(0, 10)} {Number(entry.createdAt.substring(11, 13)-3)+entry.createdAt.substring(13, 16)}hs</td>
          </tr>
        ))
      }
    </>
  );
};

export default StockCompressedRow;