import { Table } from 'react-bootstrap';
import './VoucherFilteredTable.css';

const VoucherFilteredTable = ({showedData, setShowedData}) => {
  const handleChange = (e, userEmail) => {
    if(e.target.name === "userChecked"){
      const data = showedData.concat([]);
      const index = data.findIndex(item => item.userEmail === userEmail);
      data[index].userChecked = e.target.checked;
      setShowedData(data);
    }
  }

  return (
    <Table striped bordered hover className='voucherFilteredTable-style'>
      <thead>
        <tr>
          <th className='text-center'></th>
          <th className='text-center'>#</th>
          <th className='text-center'>Usuario</th>
          <th className='text-center'>Pedidos</th>
          <th className='text-center'>Último Pedido</th>
          <th className='text-center'>$$</th>
        </tr>
      </thead>
      <tbody>
        {showedData?.map((userData, index) => (
          <tr key={index}>
            <td className='text-center'><input type="checkbox" id={`userChecked-${index}`} defaultChecked={true} name='userChecked' onChange={(e) => handleChange(e, userData.userEmail)} /> </td>
            <td>{index+1}</td>
            <td>{userData.userEmail}</td>
            <td className='text-center'>{userData.orders.length > 0 ? userData.orders.length : "0"}</td>
            <td className='text-center'>{userData.orders.length > 0 ? userData.orders[userData.orders.length-1].createdAt.substring(0, 10) : "-"}</td>
            <td className='text-center text-success fw-bold'>{userData.orders.length > 0 ? new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(Number(userData.orders.map(item => item.totalPrice).reduce((a, b) => a+b, 0))) : "-"}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default VoucherFilteredTable;