const checkUnitError = (unitArray) => {
  let error = false;
  unitArray.forEach(unit => {
    if(unit !== unitArray[0]){
      error = true;
    }
  });
  return error;
};

export default checkUnitError;