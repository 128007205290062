import { Route, Routes } from "react-router-dom";
import RegistrationPage from "./pages/RegistrationPage/RegistrationPage";
import 'bootstrap/dist/css/bootstrap.min.css';
import HomePage from "./pages/HomePage/HomePage";
import ProductEntryPage from "./pages/ProductEntryPage/ProductEntryPage";
import ProductsState from "./context/Products/ProductsState";
import VoucherPage from "./pages/VoucherPage/VoucherPage";
import OrdersPage from "./pages/OrdersPage/OrdersPage";
import UserState from "./context/Users/UserState";
import NoEntryPage from "./pages/NoEntryPage/NoEntryPage";
import StockPage from "./pages/StockPage/StockPage";
import StockViewPage from "./pages/StockViewPage/StockViewPage";
import UpdateProductPage from "./pages/UpdateProductPage/UpdateProductPage";
import UserOrdersPage from "./pages/UserOrdersPage/UserOrdersPage";
import ConsumptionPage from "./pages/ConsumptionPage/ConsumptionPage";
import StockOutPage from "./pages/StockOutPage/StockOutPage";
import DeliveryPage from "./pages/DeliveryPage/DeliveryPage";
import LogisticPage from "./pages/LogisticPage/LogisticPage";
import MessagePage from "./pages/MessagePage/MessagePage";

function App() {
  return (
    <>
      <UserState>
        <ProductsState>
          <Routes>
            <Route path="/" element={<RegistrationPage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/stock" element={<StockPage />} />
            <Route path="/stockout" element={<StockOutPage />} />
            <Route path="/stockview" element={<StockViewPage />} />
            <Route path="/updateproductdata" element={<UpdateProductPage />} />
            <Route path="/productentry" element={<ProductEntryPage />} />
            <Route path="/vouchers" element={<VoucherPage />} />
            <Route path="/orders" element={<OrdersPage />} />
            <Route path="/userorders" element={<UserOrdersPage />} />
            <Route path="/consumption" element={<ConsumptionPage />} />
            <Route path="/delivery" element={<DeliveryPage />} />
            <Route path="/logistic" element={<LogisticPage />} />
            <Route path="/message" element={<MessagePage />} />
            <Route path="*" element={<NoEntryPage />} />
          </Routes>
        </ProductsState>
      </UserState>
    </>
  );
}

export default App;
