export const getWeekDates = (plus, weekDates, firstDateLastWeek, firstDateNextWeek) => {
  if(weekDates.length === 0){
    const weekDatesArray = [];
    const dayData = {
      day: new Date().getDay(),
      date: new Date().getDate(),
      month: new Date().getMonth(),
      year: new Date().getFullYear()
    }
    let todayDate;
    const monthCalendar = monthDates(dayData.year, dayData.month);
    for(let i = -9; i < 6; i++){
      if(dayData.day === i){
        weekDatesArray.push(`${dayData.date}/${dayData.month}/${dayData.year}`);
        todayDate = `${dayData.date}/${dayData.month}/${dayData.year}`;
      }else{
        let dateSup = dayData.date-dayData.day+i;
        if(dateSup > monthCalendar.endDate && dayData.month+1 < 12){
          weekDatesArray.push(`${dateSup-monthCalendar.endDate}/${dayData.month+1}/${dayData.year}`);
        }else if(dateSup > monthCalendar.endDate && dayData.month+1 >= 12){
          weekDatesArray.push(`${dateSup-monthCalendar.endDate}/${0}/${dayData.year+1}`);
        }else if(dateSup < 1 && dayData.month-1 >= 0){
          weekDatesArray.push(`${monthCalendar.endDatePrev+dateSup}/${dayData.month-1}/${dayData.year}`);
        }else if(dateSup < 1 && dayData.month-1 < 0){
          weekDatesArray.push(`${monthCalendar.endDatePrev+dateSup}/${11}/${dayData.year-1}`);
        }else{
          weekDatesArray.push(`${dayData.date-dayData.day+i}/${dayData.month}/${dayData.year}`);
        }
      }
    }
    return {
      firstDateLastWeek: weekDatesArray[0],
      firstDateNextWeek: weekDatesArray[14],
      weekDates: weekDatesArray.slice(7, 14),
      todayDate
    }
  }else{
    let firstDay;
    plus === 1 ? firstDay = firstDateNextWeek : firstDay = firstDateLastWeek;
    const weekDatesArray = [];
    const dayData = {
      day: 0,
      date: Number(firstDay.split("/")[0]),
      month: Number(firstDay.split("/")[1]),
      year: Number(firstDay.split("/")[2])
    }
    const monthCalendar = monthDates(dayData.year, dayData.month);
    for(let i = -7; i < 8; i++){
      let dateSup = dayData.date-dayData.day+i;
      if(dateSup > monthCalendar.endDate && dayData.month+1 < 12){
        weekDatesArray.push(`${dateSup-monthCalendar.endDate}/${dayData.month+1}/${dayData.year}`);
      }else if(dateSup > monthCalendar.endDate && dayData.month+1 >= 12){
        weekDatesArray.push(`${dateSup-monthCalendar.endDate}/${0}/${dayData.year+1}`);
      }else if(dateSup < 1 && dayData.month-1 >= 0){
        weekDatesArray.push(`${monthCalendar.endDatePrev+dateSup}/${dayData.month-1}/${dayData.year}`);
      }else if(dateSup < 1 && dayData.month-1 < 0){
        weekDatesArray.push(`${monthCalendar.endDatePrev+dateSup}/${11}/${dayData.year-1}`);
      }else{
        weekDatesArray.push(`${dayData.date-dayData.day+i}/${dayData.month}/${dayData.year}`);
      }
    }
    return {
      firstDateLastWeek: weekDatesArray[0],
      firstDateNextWeek: weekDatesArray[14],
      weekDates: weekDatesArray.slice(7, 14)
    }
  }
}

const monthDates = (year, month) => { //me modifica el estado monthCalendar
  return {
    start: new Date(year, month, 1).getDay(), //Primer dia del mes (mie=3)
    endDate: new Date(year, month + 1, 0).getDate(),//Ultimo dia del mes (30)
    end: new Date(year, month, (new Date(year, month + 1, 0).getDate())).getDay(),//Dia de la semana del ultimo dia del mes (vie=5)
    endDatePrev: new Date(year, month, 0).getDate()//Ultimo dia del mes anterior (31)
  }
}