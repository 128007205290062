import { useContext, useEffect, useState } from 'react';
import { IMAGE_URL } from '../../constants';
import './Layout.css';
import UserContext from '../../context/Users/UserContext';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import useMediaQuery from '../../hooks/useMediaQuery';

const Layout = ({children}) => {
  const { auth, logout } = useContext(UserContext);
  let navigate = useNavigate();
  const [spinner, setSpinner] = useState(false);
  const [success, setSuccess] = useState(false);
  const {width} = useMediaQuery();

  const getOut = () => {
    if(spinner) return;
    setSpinner(true);
    setSuccess(true);
  }

  useEffect(() => {
    if(success){
      setTimeout(() => {
        setSpinner(false);
        setSuccess(false);
        logout();
        navigate('/');
      }, 500);
    }
  }, [success])

  return (
    <div className='layout-style' style={{backgroundImage: `url(${IMAGE_URL+'not-products/back-fruits-1.webp'})`}}>
      {
        auth ? <div className={`logout-style rounded-pill mx-2 my-2 bg-light ${width > 992 ? null : "d-none"}`} onClick={getOut}>Cerrar Sesión</div> : null
      }
      {
        spinner ? (
          <>
            <div className='form-spinner'><Spinner /></div>
            {children}
          </>
        ) : children
      }
    </div>
  );
};

export default Layout;