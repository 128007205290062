import { Table } from 'react-bootstrap';
import './OrderTable.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faBolt } from '@fortawesome/free-solid-svg-icons';
import { Fragment, useEffect, useState } from 'react';
import axiosClient from '../../settings/axiosClient';
import PopUp from '../PopUp/PopUp';
import { firstUpperCase } from '../../helpers/firstUpperCase';

const OrderTable = ({ order, btnActive, pendingOrders, setPendingOrders, setShowedOrders, products, ordersRtg, setOrdersRtg, sortOrdersByDeliveryDate }) => {
  const [showComments, setShowComments] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({});
  const [tableSpinner, setTableSpinner] = useState(false);
  const [errorPopUp, setErrorPopUp] = useState(false);
  const orderTime = (Number(order.createdAt.substring(11, 13))-3) > 0 ? Number(order.createdAt.substring(11, 13))-3+order.createdAt.substring(13, 16) : Number(order.createdAt.substring(11, 13))+21+order.createdAt.substring(13, 16);
  const userAddress = `${order.orderAddress?.street ?? ""}${order.orderAddress?.number?.length !== 0 ? ` ${order.orderAddress?.number}` : ""}${order.orderAddress?.neighbour?.length !== 0 ? ` (${order.orderAddress?.neighbour})` : ""} - ${order.orderAddress?.town} - ${order.orderAddress?.province}`;
  const [orderPlaced, setOrderPlaced] = useState([]);

  const updateOrderStatus = async () => {
    if(spinner || tableSpinner) return;
    const newStatus = order.orderStatus === "orderplaced" ? "orderrtpu" : "orderow";
    setTableSpinner(true);
    let pendingOrdersSup = pendingOrders.concat([]);
    const dataDB = {
      _id: order._id,
      orderStatus: newStatus, 
      client: order.ownerName,
      clientPhone: order.ownerPhone
    };
    try {
      const response = await axiosClient.post("/orders/management", dataDB);
      if (response.status === 200) {
        if(newStatus === 'orderow'){
          setOrdersRtg(ordersRtg.filter(order => order._id !== dataDB._id));
          setSuccess(true);
        }else if(newStatus === 'orderrtpu'){
          setPendingOrders(pendingOrdersSup.filter(order => order._id !== dataDB._id));
          setOrdersRtg(sortOrdersByDeliveryDate(ordersRtg.concat([{...order, orderStatus: newStatus}])));
          setSuccess(true);
        }
      }
    } catch (error) {
      setErrors({server: 'Error en el servidor, intente nuevamente.'})
    }
  }

  const errorPopUpFunction = () => {
    setErrorPopUp(false);
    setErrors({});
  }

  useEffect(() => {
    if(success){
      setSpinner(false);
      setSuccess(false);
      setTableSpinner(false);
    }
  }, [success])

  useEffect(() => {
    if(Object.keys(errors).length !== 0){
      setTimeout(() => {
        setSpinner(false);
        setTableSpinner(false);
        setErrorPopUp(true);
      }, 1000);
    }
  }, [errors])

  useEffect(() => {
    setOrderPlaced(order.orderPlaced.map((product) => (
      {
        ...product,
        itemRank: products.find(item => item._id === product.itemId)?.weightRank
      }
    )));
  }, [order])

  return (
    <div className='m-3'>
      <Table striped bordered hover className='order-table-style position-relative'>
        {
          tableSpinner ? (
            <tbody><tr className="form-spinner"><td className='loader'></td></tr></tbody>
          ) : null
        }
        <thead>
          <tr>
            <td colSpan={3}>Pedido de: <span className='fw-bold'>{order.ownerName}</span></td>
          </tr>
          <tr>
            <td colSpan={3}>Orden Efectuada: <span className='fw-bold'>{order.createdAt.substring(8, 10)}/{order.createdAt.substring(5, 7)}/{order.createdAt.substring(0, 4)}</span></td>
          </tr>
          <tr>
            <td colSpan={3}>Hora Efectuada: <span className='fw-bold'>{orderTime}</span></td>
          </tr>
          <tr>
            <td colSpan={3}>Entrega: <span className='fw-bold'>{order.deliveryDate.substring(0, 5)} | {order.deliveryTime?.substring(0, 2)}am - {order.deliveryTime?.substring(0, 2) === "12" ? "1pm" : order.deliveryTime?.substring(2, 4) + "am"}</span></td>
          </tr>
        </thead>
        <tbody>
          {
            orderPlaced.sort((a, b) => a.itemRank - b.itemRank).map((order, index) => (
              <Fragment key={index}>
                <tr className={order.productNote?.length !== 0 ? "border-bottom-0" : ""}>
                  <td>{Number(order.quantity).toFixed(2)}{order.soldUnit}</td>
                  <td>{firstUpperCase(products.find(item => item._id === order.itemId)?.productName ?? "")}</td>
                  <td className='price-width'>{Number(order.pricePerUnit*order.quantity).toFixed(2)}</td>
                </tr>
                {
                  order.productNote && (
                    <tr className='border-top-0'>
                      <td colSpan={3} className='w-300px'>{order.productNote}</td>
                    </tr>
                  )
                }
              </Fragment>
            ))
          }
          <tr>
            <td colSpan={3}>Total: <span className='fw-bold'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(Number(order.totalPrice))}</span></td>
          </tr>
          <tr>
            <td colSpan={3}>Dirección: {userAddress} {order.orderAddress?.comments?.length !== 0 && !showComments ? <span className='pointer fw-bold text-warning ms-2' onClick={() => setShowComments(true)}><FontAwesomeIcon icon={faAngleDown} /></span> : null}</td>
          </tr>
          {
            showComments ? (
              <tr>
                <td colSpan={3} className='w-300px'>{order.orderAddress?.comments} <span className='pointer fw-bold text-warning ms-2' onClick={() => setShowComments(false)}><FontAwesomeIcon icon={faAngleUp} /></span></td>
              </tr>
            ) : null
          }

          {
            order.orderNote && order.orderNote.length !== 0 && (
              <tr>
                <td colSpan={3} className='w-300px'>Nota: {order.orderNote}</td>
              </tr>
            )
          }
      
          {
            spinner ? (
              <>
                <tr className='position-relative'>
                  <td colSpan={3} className="form-spinner h-24 border border-top-0"><span className='loader'></span></td>
                </tr>
                <tr>
                  <td colSpan={3} >hola</td>
                </tr>
              </>
            ) : (
              <tr>
                <td colSpan={btnActive === "pending" ? 2 : 3}>Estado: <span className='fw-bold'>{order.orderStatus === "orderplaced" ? "Orden Recibida" : (order.orderStatus === "orderrtpu" ? "Orden Preparada" : (order.orderStatus === "orderow" ? "Orden en Camino" : (order.orderStatus === "orderreceived" ? "Orden Entregada" : null)))}</span></td>
                {
                  btnActive === 'pending' ? (
                    <td className='text-center pointer updateOrder-btn' onClick={updateOrderStatus}><FontAwesomeIcon icon={faBolt} /></td>
                  ) : null
                }
              </tr>
            )
          }
        </tbody>
      </Table>
      <PopUp popUp={errorPopUp} setPopUp={setErrorPopUp} popUpTitle={"ERROR"} popUpText={Object.values(errors).join(', ')} redBorder={true} popUpBtnFunction={errorPopUpFunction} popUpBtnText={"OK"} noWayOfClose={true} />
    </div>
  );
};

export default OrderTable;