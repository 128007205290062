import { useReducer } from "react"
import UserReducer from "./UserReducer"
import axiosClient from "../../settings/axiosClient";
import { POST_USER, REMOVE_USER } from "../../type";
import UserContext from "./UserContext";

const UserState = ({ children }) => {
  const initialState = {
    auth: false,
    sign: '',
    admin: false
  }

  //NO TIENE MUCHO SENTIDO TODAVIA LO DEL ADMIN KEY PERO YA LO VA A TOMAR
  //EL ADMIN KEY SE MODIFICA ALEATORIAMENTE CADA QUE VEZ QUE SE INICIA SESION DE CON EL EMAIL

  const [state, dispatch] = useReducer(UserReducer, initialState);

  const login = async (data) => {
    let errors = {};
    try {
      const response = await axiosClient.post('/users/adminuser', data);
      if(response.status === 200){
        localStorage.setItem('ID', response.data.id);
        axiosClient.defaults.headers.common['x-auth-token'] = response.data.id;
        dispatch({
          type: POST_USER,
          payload: response.data
        });
      }else if(response.status === 201){
        errors.data = "Credenciales Incorrectas";
        dispatch({
          type: REMOVE_USER
        });
      }
      return errors;
    } catch (error) {
      console.log(error);
      if(error.response?.status === 422){
        errors.data = "Credenciales Incorrectas";
      }else{
        errors.server = 'Error en la petición, inténtelo nuevamente';
      }
      dispatch({
        type: REMOVE_USER
      });
      return errors;
    }
  }

  const getAuth = async () => {
    const token = localStorage.getItem('ID');
    if(token){
      axiosClient.defaults.headers.common['x-auth-token'] = token;
      try {
        const response = await axiosClient.get('/users/adminuser');
        if(response.status === 200){
            dispatch({
              type: POST_USER,
              payload: response.data
            })
          }else{
            dispatch({
              type: REMOVE_USER
            })
          }
        } catch (error) {
        dispatch({
          type: REMOVE_USER
        })
      }
    }else{
      dispatch({
        type: REMOVE_USER
      })
    }
  }

  const logout = () => {
    dispatch({
      type: REMOVE_USER
    })
  }

  return (
    <UserContext.Provider value={{
      auth: state.auth,
      sign: state.sign,
      admin: state.admin,
      login,
      getAuth,
      logout
    }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserState;