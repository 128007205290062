import { useContext, useEffect, useState } from 'react';
import AddStockTable from '../../components/AddStockTable/AddStockTable';
import Layout from '../../components/Layout/Layout';
import AdminRoute from '../../routes/AdminRoute';
import './StockPage.css';
import ProductsContext from '../../context/Products/ProductsContext';
import PopUp from '../../components/PopUp/PopUp';
import Spinner from '../../components/Spinner/Spinner';

const StockPage = () => {
  const { stock, getStock, products, getAllProducts } = useContext(ProductsContext);
  const [errorPopUp, setErrorPopUp] = useState(false);
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const getStockFunction = async () => {
    const getStockErrors = await getStock();
    const getProductsErrors = await getAllProducts();
    if(Object.keys(getStockErrors). length !== 0 || Object.keys(getProductsErrors). length !== 0){
      if(Object.keys(getProductsErrors). length !== 0){
        setErrors(getProductsErrors);
      }else{
        setErrors(getStockErrors);
      }
      return;
    };
    setSuccess(true);
  }

  const popUpBtnFunction = () => {
    setErrorPopUp(false);
    setErrors({});
  }

  useEffect(() => {
    if(stock.length === 0 || products.length === 0){
      getStockFunction();
      setSpinner(true);
    }
  }, [])

  useEffect(() => {
    if(Object.keys(errors).length !== 0){
      setTimeout(() => {
        setSpinner(false);
        setErrorPopUp(true);
      }, 1500)
    }
  }, [errors])

  useEffect(() => {
    if(success){
      setSpinner(false);
      setSuccess(false);
    }
  }, [success])

  return (
    <Layout>
      <AdminRoute>
        <div className='stockPage-style'>
          {
            spinner ? <div className="form-spinner"><Spinner /></div> : null
          }
          <AddStockTable />
        </div>
        <PopUp popUp={errorPopUp} setPopUp={setErrorPopUp} popUpTitle={"ERROR"} popUpText={Object.values(errors).join(', ')} redBorder={true} popUpBtnFunction={popUpBtnFunction} popUpBtnText={"OK"} noWayOfClose={true} />
      </AdminRoute>
    </Layout>
  );
};

export default StockPage;