import { Button, Spinner, Table } from 'react-bootstrap';
import Layout from '../../components/Layout/Layout';
import AdminRoute from '../../routes/AdminRoute';
import './DeliveryPage.css';
import axiosClient from '../../settings/axiosClient';
import { useEffect, useState } from 'react';
import { getDeliveryWeekDates } from '../../helpers/getDeliveryWeekDates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { getTwoDigits } from '../../helpers/getDayData';
import PopUp from '../../components/PopUp/PopUp';

const DeliveryPage = () => {
  const deliveryTimes = ['0910', '1011', '1112', '121p'];
  const [ordersToDeliver, setOrdersToDeliver] = useState([]);
  const [weekDates, setWeekDates] = useState([]);
  const [weeklyOrders, setWeeklyOrders] = useState([]);
  const [firstDateNextWeek, setFirstDateNextWeek] = useState("");
  const [firstDateLastWeek, setFirstDateLastWeek] = useState("");
  const [todayDate, setTodayDate] = useState("");
  const [plus, setPlus] = useState(0);
  const [updateDeliverys, setUpdateDeliverys] = useState(false);
  const [deliveryLimits, setDeliveryLimits] = useState({});
  const [thisWeekLimit, setThisWeekLimit] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [nexWeekLimit, setNextWeekLimit] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [errorPopUp, setErrorPopUp] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const getOrdersToDeliverAndDeliveryLimit = async () => {
    if(spinner) return;
    setSpinner(true);
    try {
      const orders = await axiosClient.get('/orders/delivery-orders');
      const deliveryLimits = await axiosClient.get('/deliverylimit');
      if(orders.status === 200 && deliveryLimits.status === 200){
        setOrdersToDeliver(orders.data);
        setDeliveryLimits(deliveryLimits.data);
        setThisWeekLimit(deliveryLimits.data.thisWeek);
        setNextWeekLimit(deliveryLimits.data.nextWeek);
        setSuccess(true);
      }else{
        setErrors({servidor: 'Error al traer los datos desde el servidor. Intente nuevamente.'});
      }
    } catch (error) {
      setErrors({servidor: 'Error al traer los datos desde el servidor. Intente nuevamente.'});
    }
  }

  const updateDeliveryLimit = async () => {
    if(spinner) return;
    setSpinner(true);
    try {
      const dataDB = {...deliveryLimits, thisWeek: thisWeekLimit, nextWeek: nexWeekLimit};
      const response = await axiosClient.post('/deliverylimit', dataDB);
      if(response.status === 200){
        setUpdateDeliverys(false);
        setDeliveryLimits(dataDB);
        setPlus(0);
        setSuccess(true);
      }
    } catch (error) {
      setErrors({servidor: 'Error al procesar el pedido. Intente nuevamente.'});
    }
  }

  const handleWeek = (plus) => {
    const weekData = getDeliveryWeekDates(plus, weekDates, firstDateLastWeek, firstDateNextWeek);
    setWeekDates(weekData.weekDates);
    setFirstDateLastWeek(weekData.firstDateLastWeek);
    setFirstDateNextWeek(weekData.firstDateNextWeek);
  }

  const updateWeeklyOrders = () => {
    let weeklyOrdersSup = [];
    weekDates.forEach(date => {
      weeklyOrdersSup.push(ordersToDeliver.filter(order => order.deliveryDate ===`${getTwoDigits(date.split('/')[0])}/${getTwoDigits(Number(date.split('/')[1])+1)}/${date.split('/')[2]}`));
    })
    setWeeklyOrders(weeklyOrdersSup);
  }

  const handleChange = (e, index) => {
    if(plus === 0){
      setThisWeekLimit(thisWeekLimit.map((limit, i) => i === index ? (e.target.value.length !== 0 ? Number(e.target.value) : e.target.value) : limit));
    }else if(plus === 1){
      setNextWeekLimit(nexWeekLimit.map((limit, i) => i === index ? (e.target.value.length !== 0 ? Number(e.target.value) : e.target.value) : limit));
    }
  }

  const popUpBtnFunction = () => {
    setErrorPopUp(false);
    setErrors({});
  }

  useEffect(() => {
    if(ordersToDeliver.length === 0 || Object.keys(deliveryLimits).length === 0) getOrdersToDeliverAndDeliveryLimit();
    const weekData = getDeliveryWeekDates(0, []);
    setWeekDates(weekData.weekDates);
    setFirstDateLastWeek(weekData.firstDateLastWeek);
    setFirstDateNextWeek(weekData.firstDateNextWeek);
    setTodayDate(weekData.todayDate);
  }, [])

  useEffect(() => {
    if(weekDates.length !== 0 && ordersToDeliver.length !== 0) updateWeeklyOrders();
  }, [weekDates, ordersToDeliver])

  useEffect(() => {
    if(weekDates.length !== 0) handleWeek(plus);
  }, [plus])

  useEffect(() => {
    if(success){
      setSpinner(false);
      setSuccess(false);
    }
  }, [success])

  useEffect(() => {
    if(Object.keys(errors).length !== 0){
      setSpinner(false);
      setErrorPopUp(true);
    }
  }, [errors])

  return (
    <Layout>
      <AdminRoute>
        <div className='delivery-style pt-5'>
          {
            spinner ? <div className="form-spinner"><Spinner /></div> : null
          }
          <div className='w-100 d-flex justify-content-between my-3'>
            <Button className={plus === 0 ? "invisible" : ""} variant='outline-dark' onClick={() => setPlus(0)}><FontAwesomeIcon icon={faAngleLeft} /></Button>
            <Button className={plus === 1 ? "invisible" : "ms-3"} variant='outline-dark' onClick={() => setPlus(1)}><FontAwesomeIcon icon={faAngleRight} /></Button>
          </div>
          <Table striped bordered hover>
            <thead>
              {
                weekDates.length !== 0 && (
                  <tr>
                    <th className='text-center fw-light'>
                      Turnos Delivery
                      <div className="invisible d-flex justify-content-center align-items-center">
                        Ped/hr:
                        <input className="ms-2 w-40" type="text" />
                      </div>
                    </th>
                    <th className={`text-center fw-light ${todayDate === weekDates[0] ? "text-success fw-bold" : ""}`}>
                      Lun {getTwoDigits(weekDates[0].split('/')[0])}/{getTwoDigits(Number(weekDates[0].split('/')[1])+1)}
                      <div className={`d-flex justify-content-center align-items-center ${!updateDeliverys ? "invisible" : ""}`}>
                        Ped/hr:
                        <input className="ms-2 w-40" type="number" value={plus === 0 ? thisWeekLimit[0] : nexWeekLimit[0]} onChange={(e) => handleChange(e, 0)} />
                      </div>
                    </th>
                    <th className={`text-center fw-light ${todayDate === weekDates[1] ? "text-success fw-bold" : ""}`}>
                      Mar {getTwoDigits(weekDates[1].split('/')[0])}/{getTwoDigits(Number(weekDates[1].split('/')[1])+1)}
                      <div className={`d-flex justify-content-center align-items-center ${!updateDeliverys ? "invisible" : ""}`}>
                        Ped/hr:
                        <input className="ms-2 w-40" type="number" value={plus === 0 ? thisWeekLimit[1] : nexWeekLimit[1]} onChange={(e) => handleChange(e, 1)} />
                      </div>
                    </th>
                    <th className={`text-center fw-light ${todayDate === weekDates[2] ? "text-success fw-bold" : ""}`}>
                      Mie {getTwoDigits(weekDates[2].split('/')[0])}/{getTwoDigits(Number(weekDates[2].split('/')[1])+1)}
                      <div className={`d-flex justify-content-center align-items-center ${!updateDeliverys ? "invisible" : ""}`}>
                        Ped/hr:
                        <input className="ms-2 w-40" type="number" value={plus === 0 ? thisWeekLimit[2] : nexWeekLimit[2]} onChange={(e) => handleChange(e, 2)} />
                      </div>
                    </th>
                    <th className={`text-center fw-light ${todayDate === weekDates[3] ? "text-success fw-bold" : ""}`}>
                      Jue {getTwoDigits(weekDates[3].split('/')[0])}/{getTwoDigits(Number(weekDates[3].split('/')[1])+1)}
                      <div className={`d-flex justify-content-center align-items-center ${!updateDeliverys ? "invisible" : ""}`}>
                        Ped/hr:
                        <input className="ms-2 w-40" type="number" value={plus === 0 ? thisWeekLimit[3] : nexWeekLimit[3]} onChange={(e) => handleChange(e, 3)} />
                      </div>
                    </th>
                    <th className={`text-center fw-light ${todayDate === weekDates[4] ? "text-success fw-bold" : ""}`}>
                      Vie {getTwoDigits(weekDates[4].split('/')[0])}/{getTwoDigits(Number(weekDates[4].split('/')[1])+1)}
                      <div className={`d-flex justify-content-center align-items-center ${!updateDeliverys ? "invisible" : ""}`}>
                        Ped/hr:
                        <input className="ms-2 w-40" type="number" value={plus === 0 ? thisWeekLimit[4] : nexWeekLimit[4]} onChange={(e) => handleChange(e, 4)} />
                      </div>
                    </th>
                    <th className={`text-center fw-light ${todayDate === weekDates[5] ? "text-success fw-bold" : ""}`}>
                      Sab {getTwoDigits(weekDates[5].split('/')[0])}/{getTwoDigits(Number(weekDates[5].split('/')[1])+1)}
                      <div className={`d-flex justify-content-center align-items-center ${!updateDeliverys ? "invisible" : ""}`}>
                        Ped/hr:
                        <input className="ms-2 w-40" type="number" value={plus === 0 ? thisWeekLimit[5] : nexWeekLimit[5]} onChange={(e) => handleChange(e, 5)} />
                      </div>
                    </th>
                    <th className={`text-center fw-light ${todayDate === weekDates[6] ? "text-success fw-bold" : ""}`}>
                      Dom {getTwoDigits(weekDates[6].split('/')[0])}/{getTwoDigits(Number(weekDates[6].split('/')[1])+1)}
                      <div className={`d-flex justify-content-center align-items-center ${!updateDeliverys ? "invisible" : ""}`}>
                        Ped/hr:
                        <input className="ms-2 w-40" type="number" value={plus === 0 ? thisWeekLimit[6] : nexWeekLimit[6]} onChange={(e) => handleChange(e, 6)} />
                      </div>
                    </th>
                  </tr>
                )
              }
            </thead>
            <tbody>
              {
                weeklyOrders.length !== 0 && Object.keys(deliveryLimits).length !== 0 && deliveryTimes.map((time, index) => (
                  <tr key={index}>
                    <td className='text-center'>{time}</td>
                    {
                      weeklyOrders.map((orders, index) => (
                        <td className='text-center' key={index}>
                            {orders.filter(order => order.deliveryTime === time).length} / {' '}
                            {
                              plus === 0 ? deliveryLimits.thisWeek[index] : deliveryLimits.nextWeek[index]
                            }
                        </td>
                      ))
                    }
                  </tr>
                ))
              }
              <tr>
                <td className='text-center fw-bold'>Total Día</td>
                {
                  weeklyOrders.map((orders, index) => (
                    <td key={index} className='text-center fw-bold'>{orders.length}</td>
                  ))
                }
              </tr>
            </tbody>
          </Table>
          <Button variant='outline-dark' className={updateDeliverys ? "d-none" : ""} onClick={() => setUpdateDeliverys(true)}>Actualizar cantidad de pedidos</Button>
          <Button variant='outline-dark' className={`mt-3 w-100 ${!updateDeliverys ? "d-none" : ""}`} onClick={updateDeliveryLimit}>ACTUALIZAR</Button>
          <Button variant='secondary' className={`mt-2 w-100 ${!updateDeliverys ? "d-none" : ""}`} onClick={() => setUpdateDeliverys(false)}>CANCELAR</Button>
        </div>
        <PopUp popUp={errorPopUp} setPopUp={setErrorPopUp} popUpTitle={"ERROR"} popUpText={Object.values(errors).join(', ')} redBorder={true} popUpBtnFunction={popUpBtnFunction} popUpBtnText={"OK"} noWayOfClose={true} />
      </AdminRoute>
    </Layout>
  );
};

export default DeliveryPage;