import { useEffect, useState } from 'react';
import './PopUp.css';

const PopUp = ({ popUp, setPopUp, popUpTitle, popUpText, popUpBtnFunction, popUpBtnText, closeBtn, noWayOfClose, children, redBorder, greenBorder }) => {
  const [mouseInside, setMouseInside] = useState(false);

  const handleClick = () => {
    if (!mouseInside && !noWayOfClose) {
      setPopUp(false);
    }
  }

  const handleKeyUp = (e) => {
    if(e.keyCode === 27) setPopUp(false);
  }

  useEffect(() => {
    if(popUp && !noWayOfClose){
      window.addEventListener('keyup', handleKeyUp, true);
    }

    return () => {
      if(popUp) window.removeEventListener('keyup', handleKeyUp, true);
    }
  }, [popUp])

  return (
    <div className={`overlay ${popUp ? 'active' : null}`} id="overlay" onClick={handleClick}>
      <div className={`popup ${redBorder ? "border border-danger bold-border" : null} ${greenBorder ? "border border-success bold-border" : null}`} onMouseEnter={() => setMouseInside(true)} onMouseLeave={() => setMouseInside(false)}>
        {
          closeBtn ? (
            <span className="close" id="close" onClick={() => popUp ? setPopUp(false) : setPopUp(true)}>&times;</span>
          ) : null
        }
        {
          children ? <div>{children}</div> : (
            <div>
              <h4 className={popUpTitle === "ERROR" ? "text-danger" : null}>{popUpTitle}</h4>
              <p className='fs-5'>{popUpText}</p>
            </div>
          )
        }
        {
          popUpBtnText ? (
            <button className='popUpBtn-style' id='popup-button' onClick={popUpBtnFunction}>{popUpBtnText}</button>
          ) : null
        }
      </div>
    </div>
  );
};

export default PopUp;