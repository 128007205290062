export default (rowNumber) => {
  let arrayProductsSup = [];
  for(let i = 0; i < rowNumber; i++){
    //FILA
    let productAdded = {}
    for(let j = 0; j < 5; j++){
      let propertyValue = document.getElementById(`${i}${j}`).value;
      switch(j){
        case 0:
          productAdded.productName = propertyValue;
          break;
        case 1:
          productAdded.minUnit = propertyValue;
          break;
        case 2:
          productAdded.unit = propertyValue;
          break;
        case 3:
          productAdded.delta = propertyValue;
          break;
        case 4:
          productAdded.category = propertyValue;
          arrayProductsSup.push(productAdded)
          break;
      }
    }
  }

  return arrayProductsSup;
}