import './LogisticPage.css';
import Layout from '../../components/Layout/Layout';
import AdminRoute from '../../routes/AdminRoute';
import { Fragment, useContext, useEffect, useState } from 'react';
import axiosClient from '../../settings/axiosClient';
import ProductsContext from '../../context/Products/ProductsContext';
import { Spinner, Table } from 'react-bootstrap';
import PopUp from '../../components/PopUp/PopUp';

const LogisticPage = () => {
  const [spinner, setSpinner] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorPopUp, setErrorPopUp] = useState(false);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [deliveryDates, setDeliveryDates] = useState([]);
  const [deliveryDateSelected, setDeliveryDateSelected] = useState('');
  const [ordersSelected, setOrdersSelected] = useState([]);
  const [deliveryTimes, setDeliveryTimes] = useState([]);
  const [deliveryTimeSelected, setDeliveryTimeSelected] = useState('');
  const times = ['0910', '1011', '1112', '121p'];
  const { products, getAllProducts } = useContext(ProductsContext);
  const [orderPlacedDataSelected, setOrderPlacedDataSelected] = useState([]);
  const [orderPlacedUniqueProducts, setOrderPlacedUniqueProducts] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);

  const getProductsFunction = async () => {
    const getProductsErrors = await getAllProducts();
    if(Object.keys(getProductsErrors). length !== 0) return setErrors(getProductsErrors);
    setSuccess(true);
  }

  const getOrders = async () => {
    if(spinner) return;
    setSpinner(true);
    try {
      const response = await axiosClient.get('/orders/pending');
      if(response.status === 200){
        setPendingOrders(response.data.filter(order => order.orderStatus === 'orderplaced'));
        const uniqueDeliveryDates = [...new Set(response.data.map(order => order.deliveryDate))];
        setDeliveryDates(uniqueDeliveryDates);
        setDeliveryDateSelected(uniqueDeliveryDates[0]);
        setSuccess(true);
      }
    } catch (error) {
      setErrors({server: 'Error en el servidor, intente nuevamente.'});
    }
  }

  const getDeliveryTimes = () => {
    const uniqueDeliveryTimes = [...new Set(ordersSelected.map(order => order.deliveryTime))];
    const timesSorted = times.filter(time => uniqueDeliveryTimes.includes(time));
    setDeliveryTimes(timesSorted);
    setDeliveryTimeSelected(timesSorted[0]);
  }

  const orderData = () => {
    const dataDisplayed = pendingOrders.filter(order => order.deliveryDate === deliveryDateSelected && order.deliveryTime === deliveryTimeSelected).map(order => order.orderPlaced);
    setDataFiltered(dataDisplayed); //ordenes filtradas por la hora y el dia
    let dataConcatenated = [];
    dataDisplayed.forEach(data => {
      dataConcatenated = dataConcatenated.concat(data);
    });
    setOrderPlacedDataSelected(dataConcatenated); //orderPlaced de cada uno de los pedidos concatenados | con esto luego armo la tabla
    const uniqueProducts = [...new Set(dataConcatenated.map(product => product.itemId))];
    const uniqueProductsByWeightRank = uniqueProducts.map(product => products.find(item => item._id === product)).sort((a, b) => a.weightRank - b.weightRank);
    const uniqueProductsByName = uniqueProductsByWeightRank.map(product => ({itemId: product._id, productName: product.productName}));
    setOrderPlacedUniqueProducts(uniqueProductsByName); //diferentes productos de las ordenes ordenados de mas pesado a menos pesado
  }

  const popUpBtnFunction = () => {
    setErrorPopUp(false);
    setErrors({});
  }

  useEffect(() => {
    if(Object.keys(errors).length !== 0){
      setSpinner(false);
      setErrorPopUp(true);
    }
  }, [errors])

  useEffect(() => {
    if(success){
      setSpinner(false);
      setSuccess(false);
    }
  }, [success])

  useEffect(() => {
    if(pendingOrders.length === 0) getOrders();
    if(products.length === 0) getProductsFunction();
  }, [])

  useEffect(() => {
    if(deliveryDateSelected !== ''){
      setOrdersSelected(pendingOrders.filter(order => order.deliveryDate === deliveryDateSelected));
    }
  }, [deliveryDateSelected])

  useEffect(() => {
    if(ordersSelected.length !== 0) getDeliveryTimes();
  }, [ordersSelected])

  useEffect(() => {
    if(deliveryTimeSelected !== '') orderData();
  }, [deliveryTimeSelected])

  return (
    <Layout>
      <AdminRoute>
        <div className='logistic-style'>
          {
            spinner ? <div className="form-spinner"><Spinner /></div> : null
          }
          <div className='d-flex'>
            {
              deliveryDates.map((date, index) => (
                <div className={`border px-2 mx-1 py-1 rounded pointer ${deliveryDateSelected === date ? "btn-active" : ""}`} key={index} onClick={() => setDeliveryDateSelected(date)}>{date}</div>
              ))
            }
          </div>
          <div className='d-flex mt-3'>
            {
              deliveryTimes.map((time, index) => (
                <div className={`border px-2 mx-1 py-1 rounded pointer ${deliveryTimeSelected === time ? "btn-active" : ""}`} key={index} onClick={() => setDeliveryTimeSelected(time)}>{time}</div>
              ))
            }
          </div>
          <div className='border px-2 mx-1 py-1 rounded mt-3'>Pedidos: {dataFiltered.length}</div>
          <Table striped bordered hover className='mt-3 dataTable-style'>
            <thead>
              <tr>
                <th>PRODUCTO</th>
                <th className='text-center'>UNIDADES (Kg/Un)</th>
                <th className='text-center'>CANTIDADES</th>
              </tr>
            </thead>
            <tbody>
              {
                orderPlacedUniqueProducts.map((item, index )=> (
                  <Fragment key={index}>
                    <tr>
                      <td>{item.productName} ({orderPlacedDataSelected.find(product => product.itemId === item.itemId)?.soldUnit})</td>
                      <td className='text-center'></td>
                      <td className='text-center'></td>
                    </tr>
                    {
                      [...new Set(orderPlacedDataSelected.filter(product => product.itemId === item.itemId).map(product => product.quantity))].map((quantity, index) => ( //aca filtro el item, y defino un array unico con las diferentes cantidades que hay en el array de orderPlaced concatenado
                        <tr key={index}>
                          <td></td>
                          <td className='text-center'>{quantity}</td>
                          <td className='text-center'>{orderPlacedDataSelected.filter(product => product.itemId === item.itemId && product.quantity === quantity).length}</td>
                        </tr>
                      ))
                    }
                  </Fragment>
                ))
              }
            </tbody>
          </Table>
        </div>
        <PopUp popUp={errorPopUp} setPopUp={setErrorPopUp} popUpTitle={"ERROR"} popUpText={Object.values(errors).join(', ')} redBorder={true} popUpBtnFunction={popUpBtnFunction} popUpBtnText={"OK"} noWayOfClose={true} />
      </AdminRoute>
    </Layout>
  );
};

export default LogisticPage;