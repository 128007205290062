import { useContext, useEffect, useState } from "react";
import { Table, Button, Form } from "react-bootstrap";
import './AddStockTable.css'
import SpinnerLogic from "../SpinnerLogic/SpinnerLogic";
import PopUp from "../PopUp/PopUp";
import RowForm from "./RowForm";
import ProductsContext from "../../context/Products/ProductsContext";
import { MAX_PROFIT, MIN_PROFIT } from "../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import StockTable from "../StockTable/StockTable";
import StockCompressedTable from "../StockCompressedTable/StockCompressedTable";

const AddStockTable = () => {
  const [errors, setErrors] = useState({});
  const [spinner, setSpinner] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorPopUp, setErrorPopUp] = useState(false);
  const [successPopUp, setSuccessPopUp] = useState(false);
  const [profit, setProfit] = useState(40);
  const { editStock, postStockEntry } = useContext(ProductsContext);
  const [newStock, setNewStock] = useState({
    itemId: "",
    itemName: "",
    wholePrice: 0,
    wholeQuantity: 0,
    salesPricePerUnit: 0,
    waste: 0,
    onEdition: false,
    editionId: "",
    stockSign: ""
  });
  const [entryProducts, setEntryProducts] = useState([]);
  const [bestSuggestion, setBestSuggestion] = useState([]);
  const [edittingBorder, setEdittingBorder] = useState('');
  const [suggestionError, setSuggestionError] = useState(false);
  const [stockEntrySelected, setStockEntrySelected] = useState({});

  const sendData = async () => {
    if(spinner) return;
    setSpinner(true);
    if(newStock.onEdition){
      const editingResponse = await editStock(newStock);
      if(Object.keys(editingResponse)[0] === "server"){
        setErrors(editingResponse);
        return;
      }
      setNewStock({
        itemId: "",
        itemName: "",
        wholePrice: 0,
        wholeQuantity: 0,
        salesPricePerUnit: 0,
        waste: 0,
        onEdition: false,
        editionId: "",
        stockSign: ""
      })
      setEdittingBorder('');
    }else{
      const postingErrors = await postStockEntry(entryProducts);
      if(Object.keys(postingErrors).length > 1){
        setErrors(postingErrors);
        return;
      }
    }
    setEntryProducts([]);
    setSuccess(true);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(newStock.onEdition) return sendData();
    if(suggestionError){
      setSpinner(true);
      setErrors({server: "Seleccione un Nombre de Producto válido."})
      return;
    }
    setEntryProducts(entryProducts.concat([newStock]));
    setNewStock({
      itemId: "",
      itemName: "",
      wholePrice: 0,
      wholeQuantity: 0,
      salesPricePerUnit: 0,
      waste: 0,
      onEdition: false,
      editionId: "",
      stockSign: ""
    });
    document.getElementById('itemName').focus();
    setBestSuggestion([]);
    setStockEntrySelected({});
  }

  const handleProfit = (e) => {
    if(e.target.value !== 0){
      setProfit(e.target.value);
    }
  }

  const functionAfterPopUp = () => {
    setSuccessPopUp(false);
  }

  const functionAfterError = () => {
    setErrors({});
    setErrorPopUp(false);
  }

  const cancelEdition = () => {
    setNewStock({
      itemId: "",
      itemName: "",
      wholePrice: 0,
      wholeQuantity: 0,
      salesPricePerUnit: 0,
      waste: 0,
      onEdition: false,
      editionId: "",
      stockSign: ""
    })
    setEdittingBorder('');
    setProfit(40);
  }

  useEffect(() => {
    if(Object.keys(errors).length > 1 && errors.productsAddedCorrectly){
      setTimeout(() => {
        setSpinner(false);
        if(errors.productsAddedCorrectly.length !== 0){
          setEntryProducts(entryProducts.filter(item => !errors.productsAddedCorrectly.map(product => product.itemId).includes(item.itemId)));
        }
        setErrorPopUp(true);
      }, 1000)
    }

    if(Object.keys(errors).length === 1 && Object.keys(errors)[0] === "server"){
      setTimeout(() => {
        setSpinner(false);
        setErrorPopUp(true);
      }, 1000);
    }
  }, [errors])

  useEffect(() => {
    if(success){
      setTimeout(() => {
        setSpinner(false);
        setSuccessPopUp(true);
        setSuccess(false);
        setNewStock({
          itemId: "",
          itemName: "",
          wholePrice: 0,
          wholeQuantity: 0,
          salesPricePerUnit: 0,
          waste: 0,
          unit: ""
        });
        setProfit(40);
      }, 1000)
    }
  }, [success])

  return (
    <>
      <SpinnerLogic spinner={spinner} setSpinner={setSpinner} success={success} setSuccess={setSuccess} errors={{}} functionAfterSuccess={() => setSuccessPopUp(true)} popUpError={false} />
      <div className="m-5">
        <Form onSubmit={handleSubmit}>
          <div className='profit-label mb-3 py-1 rounded bg-light'>
            <Form.Label className='fw-bold mb-0'>PORCENTAJE DE GANANCIA BASE</Form.Label>
            <Form.Control className='wpx-60 ms-2' type="number" value={profit} onChange={handleProfit} max={MAX_PROFIT} min={MIN_PROFIT} />
            <Form.Label className='fw-bold ms-2 m-0'>%</Form.Label>
          </div>
          <Table striped bordered className="addProduct-table-style">
            <thead>
              <tr>
                <th className="hpx-50"><div className="d-flex align-items-center justify-content-start h-100">Producto</div></th>
                <th className="hpx-50"><div className="d-flex align-items-center justify-content-center h-100">Cantidad</div></th>
                <th className="hpx-50"><div className="d-flex align-items-center justify-content-center h-100">Costo [$]</div></th>
                <th className="hpx-50"><div className="d-flex align-items-center justify-content-center h-100">Desperdicio</div></th>
                <th className="hpx-50"><div className="d-flex align-items-center justify-content-center h-100"><span>Precio Venta x Un.</span></div></th>
                <th className="hpx-50"><div className="d-flex align-items-center justify-content-center h-100">%</div></th>
                <th className="hpx-50"><div className="d-flex align-items-center justify-content-center h-100">+</div></th>
              </tr>
            </thead>
            <tbody>
              <RowForm profit={profit} setProfit={setProfit} newStock={newStock} setNewStock={setNewStock} bestSuggestion={bestSuggestion} setBestSuggestion={setBestSuggestion} setEdittingBorder={setEdittingBorder} suggestionError={suggestionError} setSuggestionError={setSuggestionError} stockEntrySelected={stockEntrySelected} setStockEntrySelected={setStockEntrySelected} cancelEdition={cancelEdition} />
              <tr className={entryProducts.length === 0 ? "d-none" : null }>
                <td colSpan={6} className="text-center"><span className="fw-bold">PRODUCTOS A INGRESAR</span></td>
              </tr>
              {
                entryProducts.map((product, index) => (
                  <tr key={index}>
                    <td className="hpx-35">{product.itemName.toUpperCase()}</td>
                    <td className="hpx-35 text-center">{product.wholeQuantity} {product.unit}</td>
                    <td className="hpx-35 text-center">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(Number(product.wholePrice))}</td>
                    <td className="hpx-35 text-center">{product.waste} {product.waste !== 0 ? product.unit : null}</td>
                    <td className="hpx-35 text-center">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(Number(product.salesPricePerUnit))}</td>
                    <td className="hpx-35 text-center">{((Number(product.salesPricePerUnit/(product.wholePrice/(product.wholeQuantity-product.waste))-1)*100).toFixed(2))+"%"}</td>
                    <td className="hpx-35 text-center"><div className="delete-item rounded text-danger mx-1" onClick={() => setEntryProducts(entryProducts.filter(item => item.itemName !== product.itemName))}><FontAwesomeIcon icon={faTrash} size="xs" /></div></td>
                  </tr>
                ))
              }
              <tr className={entryProducts.length === 0 ? "d-none" : null }>
                <td colSpan={7}><Button onClick={sendData} className="w-100" variant="outline-success">Agregar Producto/s</Button></td>
              </tr>
            </tbody>
          </Table>
        </Form>
        <StockTable newStock={newStock} setNewStock={setNewStock} edittingBorder={edittingBorder} setEdittingBorder={setEdittingBorder} setSuggestionError={setSuggestionError} cancelEdition={cancelEdition} />
        <StockCompressedTable />
      </div>
      <PopUp popUp={errorPopUp} redBorder={true} setPopUp={setErrorPopUp} closeBtn={false} popUpBtnFunction={functionAfterError} popUpBtnText={"OK"}>
        
        {
          Object.keys(errors).length === 1 && errors.server ? (
            <div>
              <h4 className="text-danger">ERROR</h4>
              <p><span>{errors.server}</span></p>
            </div>
          ) : null
        }
        {
          Object.keys(errors).length > 1 ? (
            <div>
              <h4 className="text-danger">ERROR</h4>
              <p>Error al cargar el/los producto/s: <span className="fw-bold text-danger">{Object.keys(errors).filter(product => product !== "productsAddedCorrectly").join(', ')}</span>.</p>
            </div>
          ) : null
        }
        {
          Object.keys(errors).length !== 0 && errors.productsAddedCorrectly && errors.productsAddedCorrectly?.length !== 0 ? (
            <div>
              <h4 className="text-success">Productos Agregados</h4>
              <p>Productos agregados: <span className="fw-bold text-success">{errors.productsAddedCorrectly?.map(item => item.itemName).join(', ')}</span></p>
            </div>
          ) : null
        }
      </PopUp>
      <PopUp popUp={successPopUp} greenBorder={true} setPopUp={setSuccessPopUp} popUpTitle={"Éxito en la Operación"} popUpText={entryProducts.map(item => item.itemName).join(', ')} popUpBtnFunction={functionAfterPopUp} popUpBtnText={"OK"} noWayOfClose={true} />
    </>
  );
};

export default AddStockTable;