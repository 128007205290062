import './UpdatedProductDataTable.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { useContext, useEffect, useState } from 'react';
import ProductsContext from '../../context/Products/ProductsContext';
import Spinner from '../Spinner/Spinner';
import { Table } from 'react-bootstrap';
import PopUp from '../PopUp/PopUp';
import { updateProductData } from '../../helpers/checkExcelData';

const UpdatedProductDataTable = ({updatedDataArray, setUpdatedDataArray, reestablishData}) => {
  const [spinner, setSpinner] = useState(true);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({});
  const { updateProductDataArray, products } = useContext(ProductsContext);
  const [errorPopUp, setErrorPopUp] = useState(false);
  const [successPopUp, setSuccessPopUp] = useState(false);

  const sendData = async () => {
    if(spinner){
      return
    }
    setSpinner(true);
    let dataDB = [];
    let frontErrors = {};
    updatedDataArray.forEach(item => {
      const data = {
        _id: item._id,
        weightRank: item.weightRank,
        productName: item.productName,
        image: item.image,
        unit: item.unit,
        minUnit: item.minUnit,
        delta: item.delta,
        flag2: item.flag2,
        flag1: item.flag1,
        season: item.season,
        equivalent: item.equivalent,
        showItem: item.showItem
      }
      const dataError = updateProductData(data); //Validations
      if(Object.keys(dataError).length !== 0){
        data.error = dataError;
      }
      dataDB.push(data);
    })
    const errorArray = dataDB.filter(item => item.error); //filtro los productos con errores
    const goodArray = dataDB.filter(item => !item.error);
    if(errorArray.length !== 0){
      errorArray.forEach(item => {
        frontErrors[item.productName] = Object.values(item.error).join(' ');
      })
    }

    const errorUpdate = await updateProductDataArray(goodArray); //mando los productos sin errores al backend
    if(Object.keys(errorUpdate).length > 1){
      const allErrors = {...frontErrors, ...errorUpdate}; //en caso que me vengan errores del backend
      setErrors(allErrors)
      return
    }
    if(errorArray.length !== 0){
      setErrors({...frontErrors, ...errorUpdate}); //en caso que haya tenido errores en el frontend
      return;
    }
    setSuccess(true);
  }

  const functionAfterSuccess = () => {
    setSpinner(false);
    setUpdatedDataArray([]);
    setSuccess(false);
    reestablishData();
    setSuccessPopUp(false);
  }

  const errorPopUpBtnFunction = () => {
    setSpinner(false);
    setUpdatedDataArray([]);
    setErrors({});
    reestablishData();
    setErrorPopUp(false);
  }

  useEffect(() => {
    if(success){
      setTimeout(() => {
        setSuccessPopUp(true);
      }, 1500)
    }
  }, [success])

  useEffect(() => {
    if(updatedDataArray.length !== 0){
      setTimeout(() => {
        setSpinner(false);
      }, 500)
    }
  }, [updatedDataArray])

  useEffect(() => {
    if(Object.keys(errors).length > 1 && errors.productsAddedCorrectly){
      setTimeout(() => {
        setErrorPopUp(true);
      }, 1500)
    }else if(Object.keys(errors).length !== 0 && !errors.productsAddedCorrectly){ //caso que solo tenga errores de frontend
      setTimeout(() => {
        setErrorPopUp(true);
      }, 1500)
    }
  }, [errors])

  return (
    <>
      {
        spinner ? <div className="form-spinner"><Spinner /></div> : (
          <div className='shadow-style bg-light p-3 rounded my-3'>
            <div className='d-flex justify-content-between'>
              <div className='mb-3 pointer w-10 text-center' onClick={() => setUpdatedDataArray([])}><FontAwesomeIcon icon={faAngleLeft} size='xl' /></div>
              <div className='fw-bold'>PRODUCTOS EDITADOS</div>
            </div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Peso</th>
                  <th>Producto</th>
                  <th>URL Imagen</th>
                  <th>Equivalente</th>
                  <th>Unidad</th>
                  <th>Un. Mín.</th>
                  <th>Incr.</th>
                  <th className='text-center'>Bandera 2</th>
                  <th className='text-center'>Bandera 1</th>
                  <th className='text-center'>Temporada</th>
                  <th className='text-center'>Mostrar</th>
                </tr>
              </thead>
              <tbody>
                {updatedDataArray.map((product, index) => (
                  <tr key={index}>
                    <td>{index+1}</td>
                    <td className={products.find(item => item._id === product._id).weightRank !== product.weightRank ? "text-danger" : null}>{product.weightRank}</td>
                    <td className={products.find(item => item._id === product._id).productName !== product.productName ? "text-danger" : null}>{product.productName}</td>
                    <td className={products.find(item => item._id === product._id).image !== product.image ? "text-danger" : null}>{product.image}</td>
                    <td className={products.find(item => item._id === product._id).equivalent !== product.equivalent ? "text-danger" : null}>{product.equivalent}</td>
                    <td className={`text-center ${products.find(item => item._id === product._id).unit !== product.unit ? "text-danger" : null}`}>{product.unit}</td>
                    <td className={`text-center ${products.find(item => item._id === product._id).minUnit !== product.minUnit ? "text-danger" : null}`}>{product.minUnit}</td>
                    <td className={`text-center ${products.find(item => item._id === product._id).delta !== product.delta ? "text-danger" : null}`}>{product.delta}</td>
                    <td className={`text-center ${products.find(item => item._id === product._id).flag2 !== product.flag2 ? "text-danger" : null}`}>{product.flag2}</td>
                    <td className={`text-center ${products.find(item => item._id === product._id).flag1 !== product.flag1 ? "text-danger" : null}`}>{product.flag1}</td>
                    <td className={`text-center ${products.find(item => item._id === product._id).season !== product.season ? "text-danger" : null}`}>{product.season ? "SI" : "NO"}</td>
                    <td className={`text-center ${products.find(item => item._id === product._id).showItem !== product.showItem ? "text-danger" : null}`}>{product.showItem ? "SI" : "NO"}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className='updateBtn-style-static pointer border mx-5 bg-light text-center py-2 rounded-pill fw-bold' onClick={sendData}>Actualizar Datos</div>
          </div>
        )
      }
      <PopUp popUp={errorPopUp} redBorder={true} setPopUp={setErrorPopUp} closeBtn={false} popUpBtnFunction={errorPopUpBtnFunction} popUpBtnText={"OK"} noWayOfClose={true}>
        
        {
          (Object.keys(errors).length > 1 && errors.productsAddedCorrectly) || (Object.keys(errors).length !== 0 && !errors.productsAddedCorrectly) ? (
            <div className='text-start'>
              <h4 className="text-danger text-center">ERROR</h4>
              <p>Error al modificar el/los producto/s: </p>{Object.entries(errors).filter(item => item[0] !== 'productsAddedCorrectly').map((item, index) => <div key={index}><span className='text-danger fw-bold'>{item[0]}: </span>{item[1]}</div> )}<p>Por favor, revise los datos ingresados e intente nuevamente. Si el problema persiste, favor de ponerse en Contacto.</p>
            </div>
          ) : null
        }
        {
          Object.keys(errors).length !== 0 && errors.productsAddedCorrectly?.length !== 0 && errors.productsAddedCorrectly !== undefined ? (
            <div>
              <h4 className="text-success">Productos Modificados</h4>
              <p>Productos actualizados: <span className="fw-bold text-success">{errors.productsAddedCorrectly?.join(', ').toLowerCase()}</span></p>
            </div>
          ) : null
        }
      </PopUp>
      <PopUp popUp={successPopUp} greenBorder={true} setPopUp={setSuccessPopUp} popUpTitle={"Productos Actualizados Correctamente"} popUpText={updatedDataArray.map(item => item.productName).join(', ').toLowerCase()} popUpBtnFunction={functionAfterSuccess} popUpBtnText={"OK"} noWayOfClose={true} />
    </>
  );
};

export default UpdatedProductDataTable;