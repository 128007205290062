export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPDATE_PRODUCT_PRICES = 'UPDATE_PRODUCT_PRICES';

export const POST_USER = 'POST_USER';
export const REMOVE_USER = 'REMOVE_USER';

export const GET_ALL_STOCK = 'GET_ALL_STOCK';
export const EDIT_NEW_ENTRY = 'EDIT_NEW_ENTRY';
export const POST_NEW_STOCK = 'POST_NEW_STOCK';