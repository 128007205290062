import './VoucherPage.css';
import Layout from '../../components/Layout/Layout';
import AdminRoute from '../../routes/AdminRoute';
import axiosClient from '../../settings/axiosClient';
import { useEffect, useState } from 'react';
import AddVoucherTable from '../../components/AddVoucherTable/AddVoucherTable';
import VoucherTable from '../../components/VoucherTable/VoucherTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import Spinner from '../../components/Spinner/Spinner';
import PopUp from '../../components/PopUp/PopUp';
import AddGroupVoucher from '../../components/AddGroupVoucher/AddGroupVoucher';

const VoucherPage = () => {
  const [users, setUsers] = useState([]);
  const [vouchers, setVouchers] = useState([]);
  const [errors, setErrors] = useState({});
  const [tableBoolean, setTableBoolean] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorPopUp, setErrorPopUp] = useState(false);
  const [showGroupTableBoolean, setShowGroupTableBoolean] = useState(false);
  const [allOrders, setAllOrders] = useState([]);
  
  const getData = async () => {
    if(spinner) return;
    setSpinner(true);
    try {
      const usersResponse = await axiosClient.get('/users/usersemail');
      if (usersResponse.status === 200) {
        setUsers(usersResponse.data.filter(user => user.adminKey === undefined));
      }
      const vouchersResponse = await axiosClient.get('/vouchers');
      if (vouchersResponse.status === 200) {
        setVouchers(vouchersResponse.data.reverse());
      }
      const ordersResponse = await axiosClient.get('/orders/voucher');
      if (ordersResponse.status === 200) {
        setAllOrders(ordersResponse.data);
      }
      setSuccess(true);
    } catch (error) {
      setErrors({servidor: 'Error en el servidor. Intente nuevamente.'});
    }
  }
  
  const showTable = () => {
    if(showGroupTableBoolean) setShowGroupTableBoolean(false);
    if(tableBoolean){
      setTableBoolean(false);
    }else{
      setTableBoolean(true);
    }
  }

  const showGroupTable = () => {
    if(tableBoolean) setTableBoolean(false);
    if(showGroupTableBoolean){
      setShowGroupTableBoolean(false);
    }else{
      setShowGroupTableBoolean(true);
    }
  }

  useEffect(() => {
    if(users.length === 0 || vouchers.length === 0){
      getData();
    }
  }, [])

  useEffect(() => {
    if(Object.keys(errors).length !== 0){
      setTimeout(() => {
        setSpinner(false);
        setErrorPopUp(true);
      }, 1000)
    }
  }, [errors])

  useEffect(() => {
    if(!errorPopUp && Object.keys(errors).length !== 0){
      setErrors({});
    }
  }, [errorPopUp])

  useEffect(() => {
    if(success){
      setSpinner(false);
      setSuccess(false);
    }
  }, [success])

  return (
    <Layout>
      <AdminRoute>
        <div className='voucherPage-style pt-3'>
          {
            spinner ? <div className="form-spinner"><Spinner /></div> : null
          }
          <div className='voucher-form-style d-flex flex-column align-items-center'>
            <div className='bg-light w-100 voucher-tytle-box py-1 rounded text-center pointer' onClick={showTable}>Generación de Cupones Individuales<span className='ms-1' ><FontAwesomeIcon icon={tableBoolean ? faAngleUp : faAngleDown} /></span></div>
            <div className={`table-container mt-3 ${!tableBoolean ? 'd-none' : null}`}>
              <AddVoucherTable users={users} vouchers={vouchers} setVouchers={setVouchers} />
            </div>
            <div className='mt-3 bg-light w-100 voucher-tytle-box py-1 rounded text-center pointer' onClick={showGroupTable}>Generar cupones por Grupos<span className='ms-1' ><FontAwesomeIcon icon={showGroupTableBoolean ? faAngleUp : faAngleDown} /></span></div>
            <div className={`table-container mt-3 ${!showGroupTableBoolean ? 'd-none' : null}`}>
              <AddGroupVoucher users={users} vouchers={vouchers} setVouchers={setVouchers} spinner={spinner} setSpinner={setSpinner} setShowGroupTableBoolean={setShowGroupTableBoolean} showGroupTableBoolean={showGroupTableBoolean} allOrders={allOrders} />
            </div>
            <div className='mb-3 w-100 text-center mt-3 fw-bold'>CUPONES ENTREGADOS</div>
            <VoucherTable vouchers={vouchers} />
          </div>
        </div>
        <PopUp popUp={errorPopUp} setPopUp={setErrorPopUp} popUpTitle={"ERROR"} popUpText={Object.values(errors).join(', ')} redBorder={true} closeBtn={true} popUpBtnFunction={() => setErrorPopUp(false)} popUpBtnText={"OK"} />
      </AdminRoute>
    </Layout>
  );
};

export default VoucherPage;