import { CREATE_PRODUCT, EDIT_NEW_ENTRY, GET_ALL_PRODUCTS, GET_ALL_STOCK, POST_NEW_STOCK, UPDATE_PRODUCT, UPDATE_PRODUCT_PRICES } from "../../type";

export default (state, action) => {
  switch(action.type){
    case GET_ALL_PRODUCTS:
      return {
        ...state,
        products: action.payload.sort((a, b) => {
          if(a.productName < b.productName){
            return -1
          } else if(a.productName > b.productName){
            return 1
          }else {
            return 0
          }
        })
      }

    case CREATE_PRODUCT:
      return {
        ...state,
        products: state.products.concat(action.payload).sort((a, b) => {
          if(a.productName < b.productName){
            return -1
          } else if(a.productName > b.productName){
            return 1
          }else {
            return 0
          }
        })
      }

    case UPDATE_PRODUCT:
      const item = state.products.find(item => item._id === action.payload._id);
      const index = state.products.indexOf(item);
      state.products.splice(index, 1, action.payload);
      
      return {
        ...state,
        products: state.products.concat([])
      }

    case UPDATE_PRODUCT_PRICES:
      const itemUpdated = state.products.find(item => item._id === action.payload._id);
      const indexUpdated = state.products.indexOf(itemUpdated);
      state.products.splice(indexUpdated, 1, action.payload);
      
      return {
        ...state,
        products: state.products.concat([])
      }

    case GET_ALL_STOCK:
      return {
        ...state,
        stock: action.payload
      }

    case EDIT_NEW_ENTRY:
      const itemIndex = state.stock.findIndex(item => item._id === action.payload.stockEdited._id);
      state.stock.splice(itemIndex, 1, action.payload.stockEdited);
      if(action.payload.productUpdated) {
        const productIndexEdition = state.products.findIndex(item => item._id === action.payload.productUpdated._id);
        state.products.splice(productIndexEdition, 1, action.payload.productUpdated);
      }
      return {
        ...state,
        stock: state.stock.concat([]),
        products: state.products.concat([])
      }

    case POST_NEW_STOCK:
      return {
        ...state,
        stock: [action.payload.entry].concat(state.stock),
      }
  }
}