import axiosClient from "../settings/axiosClient";

export const postIndividualVoucher = async (data, index, defaultDate) => {
  let errors = {};
  try {
    const response = await axiosClient.post('/vouchers/createvoucher', data);
    if(response.status === 200){
      document.getElementById(`discountType%-${index}`).checked = false;
      document.getElementById(`discountType$-${index}`).checked = false;
      document.getElementById(`voucherDiscount-${index}`).value = null;
      document.getElementById(`date-${index}`).value = defaultDate;
      document.getElementById(`row-${index}`).focus();

    }
  } catch (error) {
    errors.server = 'Error en el servidor. Intente nuevamente.';
  }
  return errors;
}

export const postGroupVoucher = async (data) => { //Lo mando en grupo para que el proceso de mandar email no se haga tan largo.
  let errors = {};
  try {
    await axiosClient.post('/vouchers/createvoucher/group', {vouchers: data});
  } catch (error) {
    errors.server = 'Error en el servidor. Intente nuevamente.';
  }
  return errors;
}
