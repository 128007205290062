import AddProductTable from '../../components/AddProductTable/AddProductTable';
import Layout from '../../components/Layout/Layout';
import AdminRoute from '../../routes/AdminRoute';
import './ProductEntryPage.css';

const ProductEntryPage = () => {
  return (
    <Layout>
      <AdminRoute>
        <div className='productEntry-style'>
          <AddProductTable />
        </div>
      </AdminRoute>
    </Layout>
  );
};

export default ProductEntryPage;