import { useContext, useEffect } from 'react';
import LoginForm from '../../components/Forms/LoginForm';
import Layout from '../../components/Layout/Layout';
import './RegistrationPage.css';
import UserContext from '../../context/Users/UserContext';
import { useNavigate } from 'react-router-dom';

const RegistrationPage = () => {
  const { auth, getAuth } = useContext(UserContext);
  let navigate = useNavigate();

  useEffect(() => {
    if(auth){
      navigate('/home');
    }else{
      getAuth();
    }
  }, [auth])

  return (
    <Layout>
      <LoginForm />
    </Layout>
  );
};

export default RegistrationPage;