import { useContext, useEffect, useState } from 'react';
import Spinner from '../Spinner/Spinner';
import { getDayData } from '../../helpers/getDayData';
import './AddVoucherTable.css';
import { postIndividualVoucher } from './../../helpers/postVoucher'
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlaneDeparture } from '@fortawesome/free-solid-svg-icons';
import PopUp from '../PopUp/PopUp';
import UserContext from '../../context/Users/UserContext';

const AddVoucherTable = ({ users, vouchers, setVouchers }) => {
  const [validDates, setValidDates] = useState([]);
  const [defaultDate, setDefaultData] = useState('');
  const [errors, setErrors] = useState({});
  const [errorPopUp, setErrorPopUp] = useState(false);
  const [successPopUp, setSuccessPopUp] = useState(false);
  const { sign } = useContext(UserContext);

  const handleClick = async (index) => {
    const user = users[index];
    const date = document.getElementById(`date-${index}`).value;
    const discountType1 = document.getElementById(`discountType%-${index}`).checked ? '%' : null;
    const discountType2 = document.getElementById(`discountType$-${index}`).checked ? '$' : null;
    const voucherDiscount = Number(document.getElementById(`voucherDiscount-${index}`).value);
    let discountType;
    let clickErrors = {};

    if(discountType1 && !discountType2){
      discountType = discountType1;
    }else if(discountType2 && !discountType1){
      discountType = discountType2;
    }else{
      clickErrors.discountType = 'Introduzca un tipo de descuento';
    }

    if(voucherDiscount === undefined || voucherDiscount.toString() === 'NaN' || voucherDiscount <= 0){
      clickErrors.voucherDiscount = 'Introduzca un valor de descuento válido. Menor a $15,000.00';
    }
    if(discountType === "$" && voucherDiscount > 15000){
      clickErrors.amount = 'El valor de descuento debe ser menor a $15,000.00';
    }
    if(discountType === "%" && voucherDiscount > 50){
      clickErrors.amount = 'El valor de descuento debe ser menor a 50.00%';
    }
    rowAnimation(index, clickErrors, 1);

    if(Object.keys(clickErrors).length === 0){
      const data = {
        voucherOwner: user.userEmail,
        voucherDiscount: Number(voucherDiscount.toFixed(2)),
        discountType,
        validDate: date,
        valid: true,
        userName: user.userShortName,
        userPhone: user.userPhone,
        sign
      }
      document.getElementById(`btn-${index}`).disabled = true;
      document.getElementById(`voucherDiscount-${index}`).disabled = true;
      const postErrors = await postIndividualVoucher(data, index, defaultDate);
      if(Object.keys(postErrors).length === 0){
        setVouchers([data].concat(vouchers));
        rowAnimation(index, clickErrors, 2)
      }else{
        setErrors(postErrors);
        rowAnimation(index, postErrors, 0);
      }
    }else{
      setErrors(clickErrors);
      rowAnimation(index, clickErrors, 0);
    }
  }

  const handleKeyUp = (e, index) => {
    if(e.target.name === 'voucherDiscount'){
      if((e.keyCode >= 48 && e.keyCode <= 57)|| (e.keyCode >= 96 && e.keyCode <= 105) || (e.keyCode === 8) || (e.keyCode === 46) || (e.keyCode === 13) || (e.keyCode === 9) || (e.keyCode === 110) || (e.keyCode === 190)){
        if(e.target.classList.contains('input-error')){
          e.target.classList.remove('input-error');
        }
      }else{
        e.target.classList.add('input-error');
        e.target.value = e.target.value.substring(0, e.target.value.length - 1);
        setTimeout(() => {
          e.target.classList.remove('input-error');
        }, 500)
      }
      if(e.keyCode === 13){
        handleClick(index);
      }
    }
    if(document.getElementById(`row-${index}`).classList.contains('row-error')){
      document.getElementById(`row-${index}`).classList.remove('row-error');
    }
  }

  const rowAnimation = (index, clickErrors, stage) => {
    const row = document.getElementById(`row-${index}`);
    const spinnerRow = document.getElementById(`spinner-${index}`);
    if(stage === 1){
      row.classList.add('row-animation');
      spinnerRow.classList.remove('spinner-table_off')
      document.getElementById('root').focus();
    } else if(stage === 2){
      setTimeout(() => {
        spinnerRow.classList.add('spinner-table_off')
        row.classList.remove('row-animation');
        setSuccessPopUp(true);
      }, 1000);
    } else if(Object.keys(clickErrors).length !== 0){
      setTimeout(() => {
        spinnerRow.classList.add('spinner-table_off')
        row.classList.add('row-error');
        document.getElementById(`btn-${index}`).disabled = false;
        document.getElementById(`voucherDiscount-${index}`).disabled = false;
      }, 1000)
    }
  }

  useEffect(() => {
    //Default Valid Date
    const daysData = getDayData(15);
    setValidDates(daysData);
    setDefaultData(daysData.find(item => item[1] === true)[0]);
  }, [])

  useEffect(() => {
    if(Object.keys(errors).length !== 0){
      setTimeout(() => {
        setErrorPopUp(true);
      }, 1000)
    }
  }, [errors])

  useEffect(() => {
    if(!errorPopUp && Object.keys(errors).length !== 0){
      setErrors({});
    }
  }, [errorPopUp])

  return (
    <>
      <table className='modern-table'>
        <thead>
          <tr>
            <th className='text-center'>#</th>
            <th className='text-center'>Usuarios</th>
            <th className='text-center'>Validez</th>
            <th className='text-center'>[%]</th>
            <th className='text-center'>[$]</th>
            <th className='text-center'>Valor</th>
            <th className='text-center'>Cupón</th>
          </tr>
        </thead>
        <tbody>
          {users?.map((user, index) => (
            <tr key={index} id={`row-${index}`} className='position-relative'>
              <td>{index+1}</td>
              <td>{user.userEmail}</td>
              <td className='text-center'>
                <select name="validDate" className='select-style' id={`date-${index}`} defaultValue={defaultDate}>
                  {
                    validDates?.map((date, index) => (
                      <option key={index} value={date[0]}>{date[0]}</option>
                    ))
                  }
                </select>
              </td>
              <td className='text-center'>
                <input type="radio" name={`discountType-${index}`} id={`discountType%-${index}`} onChange={(e) => handleKeyUp(e, index)} />
              </td>
              <td className='text-center'>
                <input type="radio" name={`discountType-${index}`} id={`discountType$-${index}`} onChange={(e) => handleKeyUp(e, index)} />
              </td>
              <td className='text-center'>
                <input type="text" className='w-80' id={`voucherDiscount-${index}`} onKeyUp={(e) => handleKeyUp(e, index)} name='voucherDiscount' maxLength={8} />
              </td>
              <td className='text-center'><Button variant='outline-dark' id={`btn-${index}`} className='py-0 px-3' onClick={() => handleClick(index)}><FontAwesomeIcon icon={faPlaneDeparture} /></Button></td>
              <td className="form-spinner spinner-table_off" id={`spinner-${index}`}><Spinner /></td>
            </tr>
          ))}
        </tbody>
      </table>
      <PopUp popUp={errorPopUp} setPopUp={setErrorPopUp} popUpTitle={"ERROR"} popUpText={Object.values(errors).join(', ')} redBorder={true} closeBtn={true} popUpBtnFunction={() => setErrorPopUp(false)} popUpBtnText={"OK"} />
      <PopUp popUp={successPopUp} greenBorder={true} setPopUp={setSuccessPopUp} popUpTitle={"Cupón de Descuento Enviado"} popUpText={`Usuario: ${vouchers[0]?.voucherOwner}`} closeBtn={true} popUpBtnFunction={() => setSuccessPopUp(false)} popUpBtnText={"OK"} />
    </>
  );
};

export default AddVoucherTable;