export const IMAGE_URL = "https://storage.googleapis.com/marketar_bucket/";

export const URL = "https://dinamicbackendapps.site/api/verduleria";
//   https://proyecto-verdu-back.onrender.com/api/verduleria
//   http://localhost:4000/api/verduleria
//   https://dinamicbackendapps.site/marketarapi/verduleria

export const WHOLE_PRICE_MAX = 500000;
export const WHOLE_PRICE_MIN = 0;

export const WHOLE_QUANTITY_MAX = 500;
export const WHOLE_QUANTITY_MIN = 0;

export const PRICE_MIN = 0;
export const PRICE_MAX = 100000;

export const UNIT_MAX = 30;
export const UNIT_MIN = 0;

export const MAX_WASTE = 30;
export const MIN_WASTE = 0;

export const MAX_PROFIT = 100;
export const MIN_PROFIT = 0;

export const FLAG_MIN = 1;
export const FLAG_MAX = 30;