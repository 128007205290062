import { useContext, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import './Forms.css';
import Spinner from "../Spinner/Spinner";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/Users/UserContext";

const LoginForm = () => {
  const [userLog, setUserLog] = useState({
    userEmail: "",
    userPassword: ""
  });
  const [errors, setErrors] = useState({});
  const [spinner, setSpinner] = useState(false);
  const [success, setSuccess] = useState(false);
  let navigate = useNavigate();
  const { login } = useContext(UserContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(spinner || Object.keys(errors).length !== 0) return;
    setSpinner(true);
    const loginErrors = await login(userLog);
    if(Object.keys(loginErrors).length !== 0){
      setErrors(loginErrors);
      return;
    }
    setSuccess(true);
  };

  const handleKeyUp = (e) => {
    if(Object.keys(errors).length !== 0 && e.keyCode !== 13){
      setErrors({});
    }
    setUserLog({
      ...userLog,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSpinner(false);
        setSuccess(false);
        navigate('/home');
      }, 1500)
    }
  }, [success]);

  useEffect(() => {
    if(Object.keys(errors).length !== 0){
      setTimeout(() => {
        setSpinner(false);
      }, 500)
    }
  }, [errors])

  return (
    <Form onSubmit={handleSubmit} className="loginForm-style">
      {
        spinner ? <div className="form-spinner"><Spinner /></div> : null
      }
      <Form.Group className="my-3" controlId="formBasicEmailLogin">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="Ingresá tu email"
          onKeyUp={handleKeyUp}
          name="userEmail"
          maxLength={50}
          required
        />
        <Form.Text className="text-muted">
          No compartiremos tu dirección de email con nadie.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPasswordLogin">
        <Form.Label>Contraseña</Form.Label>
        <Form.Control
          type="password"
          placeholder="Ingresá tu contraseña"
          onKeyUp={handleKeyUp}
          name="userPassword"
          maxLength={40}
          required
        />
      </Form.Group>

      {Object.keys(errors).length !== 0
        ? Object.values(errors).map((error, index) => (
          <div className="border-danger w-100 text-danger text-center mb-2 thick-border rounded" key={index}>{error}</div>
          ))
        : null}

      <Button variant="dark" className="w-100 my-3" type="submit">
        Iniciar Sesión
      </Button>

    </Form>
  );
};

export default LoginForm;