import { Spinner } from "react-bootstrap";
import Layout from "../../components/Layout/Layout";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const NoEntryPage = () => {
  let navigate = useNavigate();
  
  useEffect(() => {
    setTimeout(() => {
      navigate('/');
    }, 3000)
  }, [])

  return (
    <Layout>
      <Spinner />
    </Layout>
  );
};

export default NoEntryPage;