import { useContext, useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import StockViewTable from '../../components/StockViewTable/StockViewTable';
import AdminRoute from '../../routes/AdminRoute';
import './StockViewPage.css';
import ProductsContext from '../../context/Products/ProductsContext';
import PopUp from '../../components/PopUp/PopUp';
import Spinner from '../../components/Spinner/Spinner';
import StockTable from '../../components/StockTable/StockTable';

const StockViewPage = () => {
  const [showTable, setShowTable] = useState(false);
  const [entrySelected, setEntrySelected] = useState(-1);
  const [stockShowed, setStockShowed] = useState([]);
  const { stock, getStock, products, getAllProducts } = useContext(ProductsContext);
  const [errorPopUp, setErrorPopUp] = useState(false);
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const getStockFunction = async () => {
    const getStockErrors = await getStock();
    const getProductsErrors = await getAllProducts();
    if(Object.keys(getStockErrors). length !== 0 || Object.keys(getProductsErrors). length !== 0){
      if(Object.keys(getProductsErrors). length !== 0){
        setErrors(getProductsErrors);
      }else{
        setErrors(getStockErrors);
      }
      return;
    };
    setSuccess(true);
  }

  const popUpBtnFunction = () => {
    setErrorPopUp(false);
    setErrors({});
  }

  //SETEO DE TECLAS PARA NAVEGAR ENTRE LAS ENTRADAS DE LA TABLA
  const tabFunction = (e) => {
    switch(e.keyCode){
      case 40:
        e.preventDefault();
        if(entrySelected === -1) setEntrySelected(0);
        if(entrySelected >= 0 && entrySelected < stockShowed.length-1) setEntrySelected(entrySelected+1);
        return;
      case 38:
        e.preventDefault();
        if(entrySelected > 0) setEntrySelected(entrySelected-1);
        return;
      case 27:
        e.preventDefault();
        setEntrySelected(-1);
        return;
    }
  }

  useEffect(() => {
    if(showTable){
      window.addEventListener("keydown", tabFunction);
    }

    return () => {
      window.removeEventListener("keydown", tabFunction);
    }
    
  }, [showTable, entrySelected])

  useEffect(() => {
    return () => {
      window.removeEventListener("keydown", tabFunction);
    }
  }, [])
  //--HASTA ACA

  //SETEO STOCKSHOWED
  useEffect(() => {
    if(stock.length !== 0 && products.length !== 0){
      const dataShowed = stock.map(item => (
        {
          ...item,
          itemName: products.find(product => product._id === item.itemId).productName
        }
      ))
      setStockShowed(dataShowed.filter(item => item.wholeQuantity !== 0 && item.published).sort((a, b) => {
        if(a.itemName < b.itemName){
          return -1
        } else if(a.itemName > b.itemName){
          return 1
        }else {
          return 0
        }
      }));
    }
  }, [stock, products])

  //TRAIGO PRODUCTOS Y STOCK
  useEffect(() => {
    if(stock.length === 0 || products.length === 0){
      getStockFunction();
      setSpinner(true);
    }
  }, [])

  useEffect(() => {
    if(Object.keys(errors).length !== 0){
      setTimeout(() => {
        setSpinner(false);
        setErrorPopUp(true);
      }, 1500)
    }
  }, [errors])

  useEffect(() => {
    if(success){
      setSpinner(false);
      setSuccess(false);
    }
  }, [success])

  return (
    <Layout>
      <AdminRoute>
        <div className='stockViewPage-style'>
          {
            spinner ? <div className="form-spinner"><Spinner /></div> : null
          }
          <StockViewTable showTable={showTable} setShowTable={setShowTable} entrySelected={entrySelected} setEntrySelected={setEntrySelected} stockShowed={stockShowed} />
          <div className='m-5'>
            <StockTable showAllEntries={true} />
          </div>
        </div>
        <PopUp popUp={errorPopUp} setPopUp={setErrorPopUp} popUpTitle={"ERROR"} popUpText={Object.values(errors).join(', ')} redBorder={true} popUpBtnFunction={popUpBtnFunction} popUpBtnText={"OK"} noWayOfClose={true} />
      </AdminRoute>
    </Layout>
  );
};

export default StockViewPage;