import { useContext, useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import AdminRoute from '../../routes/AdminRoute';
import axiosClient from '../../settings/axiosClient';
import './OrdersPage.css'; 
import Spinner from '../../components/Spinner/Spinner';
import OrderTable from '../../components/OrderTable/OrderTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateForward } from '@fortawesome/free-solid-svg-icons';
import PopUp from '../../components/PopUp/PopUp';
import ProductsContext from '../../context/Products/ProductsContext';

const OrdersPage = () => {
  const [spinner, setSpinner] = useState(false);
  const [success, setSuccess] = useState(false); 
  const [errors, setErrors] = useState({});
  const [pendingOrders, setPendingOrders] = useState([]);
  const [showedOrders, setShowedOrders] = useState([]);
  const [newOrderCame, setNewOrderCame] = useState(false);
  const [btnActive, setBtnActive] = useState('pending'); //LO DEJO AHORA PARA GENERAR MA;ANA LA PESTANA QUE QUIERO GENERAR.
  const [errorPopUp, setErrorPopUp] = useState(false);
  const { products, getAllProducts } = useContext(ProductsContext);
  const [ordersRtg, setOrdersRtg] = useState([]);
  const [showedOrdersTag, setShowedOrdersTag] = useState('pending');

  const getOrders = async () => {
    if(spinner){
      return;
    }
    setSpinner(true);
    try {
      const response = await axiosClient.get("/orders/pending");
      if(response.status === 200) {
        const allPendingOrdersComming = response.data.reverse();
        if(pendingOrders.length < allPendingOrdersComming.filter(order => order.orderStatus === 'orderplaced').length && pendingOrders.length !== 0){
          setNewOrderCame(true);
        }
        setPendingOrders(sortOrdersByDeliveryDate(allPendingOrdersComming.filter(order => order.orderStatus === 'orderplaced')));
        setOrdersRtg(sortOrdersByDeliveryDate(allPendingOrdersComming.filter(order => order.orderStatus === 'orderrtpu')));
        setShowedOrders(sortOrdersByDeliveryDate(allPendingOrdersComming.filter(order => order.orderStatus === 'orderplaced')));
        setSuccess(true);
      }
    } catch (error) {
      setErrors({server: 'Error en el servidor, intente nuevamente.'});
    }
  }

  const sortOrdersByDeliveryDate = (orders) => {
    const ordersFormatted = orders.map(order => (
      {
        ...order,
        deliveryDate: new Date(`${order.deliveryDate.split('/')[2]}-${order.deliveryDate.split('/')[1]}-${order.deliveryDate.split('/')[0]}T${order.deliveryTime.substring(0, 2)}:00:00`).getTime()
      }
    ));
    const sortOrdersByDeliveryDate = ordersFormatted.sort((a, b) => a.deliveryDate - b.deliveryDate);
    let sortedOrders = [];
    sortOrdersByDeliveryDate.forEach(order => {
      sortedOrders.push(orders.find(orderToFind => orderToFind._id === order._id));
    });
    return sortedOrders;
  }

  const getProductsFunction = async () => {
    const getProductsErrors = await getAllProducts();
    if(Object.keys(getProductsErrors). length !== 0) return setErrors(getProductsErrors);
    setSuccess(true);
  }

  const errorPopUpFunction = () => {
    setErrorPopUp(false);
    setErrors({});
  }

  useEffect(() => {
    if(products.length === 0) getProductsFunction();
    if(pendingOrders.length === 0) getOrders();
    // const interval = setInterval(() => {
    //   getOrders();
    // }, 10000);

    // return () => clearInterval(interval);
  }, [])

  useEffect(() => {
    if(newOrderCame){
      setTimeout(() => {
        setNewOrderCame(false);
      }, 3000)
    }
  }, [newOrderCame])

  useEffect(() => {
    if(success){
      setSuccess(false);
      setSpinner(false);
    }
  }, [success])

  useEffect(() => {
    if(Object.keys(errors).length !== 0){
      setTimeout(() => {
        setSpinner(false);
        setErrorPopUp(true);
      }, 1000);
    }
  }, [errors])

  useEffect(() => {
    if(showedOrdersTag === 'pending'){
      setShowedOrders(pendingOrders);
    }else if(showedOrdersTag === 'rtg'){
      setShowedOrders(ordersRtg);
    }
  }, [showedOrdersTag, pendingOrders, ordersRtg])

  return (
    <Layout>
      <AdminRoute>
        {
          spinner ? <div className="form-spinner"><Spinner /></div> : null
        }
        <div className='ordersPage-style pt-3'>
          <div className='d-flex ms-3'> 
            <div className={`border p-2 pointer ${showedOrdersTag === 'pending' ? 'btn-active' : ''}`} onClick={showedOrdersTag !== 'pending' ? () => setShowedOrdersTag('pending') : ()=> {}}>Pedidos por Armar</div>
            <div className={`border p-2 ms-3 pointer ${showedOrdersTag === 'rtg' ? 'btn-active' : ''}`} onClick={showedOrdersTag !== 'rtg' ? () => setShowedOrdersTag('rtg') : () => {}}>Pedidos Preparados</div>
            <div className={`border p-2 mx-3 reload-style ${newOrderCame ? 'btn-active' : 'pointer'}`} onClick={getOrders}><FontAwesomeIcon icon={faRotateForward} size="lg" /></div>
            {
              newOrderCame ? <div className="text-danger border border-danger px-2 mx-5 py-2 fw-bold">Pedido Ingresado!</div> : null
            }
          </div>


          <div className='ordersBox-style m-0 d-flex flex-wrap'>
            {
              showedOrders?.map((order, index) => (
                <OrderTable products={products} order={order} key={index} btnActive={btnActive} pendingOrders={pendingOrders} setPendingOrders={setPendingOrders} setShowedOrders={setShowedOrders} ordersRtg={ordersRtg} setOrdersRtg={setOrdersRtg} sortOrdersByDeliveryDate={sortOrdersByDeliveryDate} />
              ))
            }
          </div>
        </div>
        <PopUp popUp={errorPopUp} setPopUp={setErrorPopUp} popUpTitle={"ERROR"} popUpText={Object.values(errors).join(', ')} redBorder={true} popUpBtnFunction={errorPopUpFunction} popUpBtnText={"OK"} noWayOfClose={true} />
      </AdminRoute>
    </Layout>
  );
};

export default OrdersPage;