import { useContext, useEffect, useState } from "react";
import useMediaQuery from "../hooks/useMediaQuery";
import DevicePage from "../pages/DevicePage/DevicePage";
import UserContext from "../context/Users/UserContext";
import { Navigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

const AdminRoute = ({children}) => {
  const {width} = useMediaQuery();
  const [realWidth, setRealWidth] = useState(993);
  const { auth, getAuth } = useContext(UserContext);
  const [wait, setWait] = useState(true);
  const [getOut, setGetOut] = useState(false);


  useEffect(() => {
    setTimeout(() => {
      if(!auth){
        setGetOut(true);
      }
    }, 3000)
  }, [])

  useEffect(() => {
    if(!auth){
      getAuth();
    }else{
      setWait(false);
    }
  }, [auth])
  
  useEffect(() => {
    if(width > 0){
      setRealWidth(width);
    }
  }, [width])

  return realWidth > 992 ? (wait ? (getOut ? <Navigate to='/' /> : <Spinner />) : children) : <DevicePage />;
}

export default AdminRoute;