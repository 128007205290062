import { useContext, useReducer } from "react"
import ProductsReducer from "./ProductsReducer";
import axiosClient from "../../settings/axiosClient";
import { CREATE_PRODUCT, EDIT_NEW_ENTRY, GET_ALL_PRODUCTS, GET_ALL_STOCK, POST_NEW_STOCK, UPDATE_PRODUCT, UPDATE_PRODUCT_PRICES } from "../../type";
import ProductsContext from "./ProductsContext";
import UserContext from "../Users/UserContext";

const ProductsState = ({ children }) => {
  const initialState = {
    products: [],
    stock: []
  }

  const { sign } = useContext(UserContext);

  const [state, dispatch] = useReducer(ProductsReducer, initialState);

  const getAllProducts = async () => {
    let errors = {};
    try {
      const response = await axiosClient.get('/products/marketproducts');
      dispatch({
        type: GET_ALL_PRODUCTS,
        payload: response.data
      })
      return errors;
    } catch (error) {
      errors.servidor = 'Error al cargar los productos, inténtelo nuevamente recargando la página';
      return errors;
    }
  }

  const postNewMarketProduct = async (newProductsArray) => {
  let errors = {};
  let productsAddedCorrectly = [];
  for(let i=0; i< newProductsArray.length; i++){
    try {
      const response = await axiosClient.post('/products/marketproducts', newProductsArray[i]);
      if(response.status === 201){
        errors[`${newProductsArray[i].productName}`] = `El producto ${newProductsArray[i].productName} ya se encuentra en la base de datos`
      } else if(response.status === 200){
        dispatch({
          type: CREATE_PRODUCT,
          payload: response.data.product
        })
        productsAddedCorrectly.push(newProductsArray[i].productName);
      }
    } catch (error) {
      errors[`${newProductsArray[i].productName}`] = `Error al cargar el producto ${newProductsArray[i].productName}`
    }
  }
  errors.productsAddedCorrectly = productsAddedCorrectly;
  return errors;
  }

  const updateProductDataArray = async (dataDB) => {
    let errors = {};
    let productsAddedCorrectly = [];
    for(let i=0; i< dataDB.length; i++){
      try {
        const response = await axiosClient.post('/products/updatedata', dataDB[i]);
        if(response.status === 200){
          dispatch({
            type: UPDATE_PRODUCT_PRICES,
            payload: response.data.product
          })
          productsAddedCorrectly.push(dataDB[i].productName);
        }
      } catch (error) {
        errors[`${dataDB[i].productName}`] = `Error al cargar el producto ${dataDB[i].productName}`
      }
    }
    errors.productsAddedCorrectly = productsAddedCorrectly;
    return errors;
  }

  const deleteProductState = () => {
    dispatch({
      type: GET_ALL_PRODUCTS,
      payload: []
    })
  }

  const getStock = async () => {
    let errors = {};
    try {
      const response = await axiosClient.get('/stock');
      if(response.status === 200){
        dispatch({
          type: GET_ALL_STOCK,
          payload: response.data.reverse()
        })
      }
    } catch (error) {
      errors.servidor = 'Error al cargar el Stock, inténtelo nuevamente recargando la página';
    }
    return errors;
  }

  const deleteStockState = () => {
    dispatch({
      type: GET_ALL_STOCK,
      payload: []
    })
  }

  const postStockEntry = async (stockArray) => {
    let errors = {};
    let productsAddedCorrectly = [];
    for(let i=0; i<stockArray.length; i++){
      try {
        const response = await axiosClient.post('/stock/create', {...stockArray[i], sign: sign});
        if(response.status === 200){
          productsAddedCorrectly.push({...response.data.entry, itemName: stockArray[i].itemName});
          dispatch({
            type: POST_NEW_STOCK,
            payload: response.data
          })
        }
      } catch (error) {
        errors[`${stockArray[i].itemName}`] = "Error al cargar el producto. Intente Nuevamente.";
      }
    }
    errors.productsAddedCorrectly = productsAddedCorrectly;
    return errors;
  }

  const editStock = async (entry) => {
    let errors = {};
    if(entry.stockSign === sign){
      try {
        const response = await axiosClient.post('/stock/edit', {...entry, sign: sign});
        if(response.status === 200){
          dispatch({
            type: EDIT_NEW_ENTRY,
            payload: response.data
          })
        }else if(response.status === 201){
          errors.server = "El tiempo de edición ha expirado.";
        }else if(response.status === 202){
          errors.server = "No tiene permisos para editar esta entrada de Stock. La operación debe ser realizada por el usuario que realizó la entrada."
        }
      } catch (error) {
        errors.server = "Error en el Servidor. Intente Nuevamente"
      }
    }else{
      errors.server = "No tiene permisos para editar esta entrada de Stock. La operación debe ser realizada por el usuario que realizó la entrada."
    }
    return errors;
  }

  const publishEntry = async (entryId, itemId) => {
    let errors = {};
    try {
      const response = await axiosClient.post('/stock/publish', {entryId: entryId, itemId: itemId});
      if(response.status === 200){
        dispatch({
          type: EDIT_NEW_ENTRY,
          payload: response.data
        })
      }
    } catch (error) {
      errors.server = "Error en el Servidor. Intente Nuevamente"
    }
    return errors;
  }

  const updateSalesPrice = async (id, salesPrice) => {
    let errors = {};
    try {
      const response = await axiosClient.post('/stock/updatesalesprice', {id, salesPrice});
      if(response.status === 200){
        dispatch({
          type: EDIT_NEW_ENTRY,
          payload: response.data
        })
      }
    } catch (error) {
      errors.server = "Error en el Servidor. Intente Nuevamente"
    }
    return errors;
  }

  return(
    <ProductsContext.Provider value={{
      products: state.products,
      stock: state.stock,
      getAllProducts,
      postNewMarketProduct,
      updateProductDataArray,
      deleteProductState,
      getStock,
      deleteStockState,
      editStock,
      postStockEntry,
      publishEntry,
      updateSalesPrice
    }}>
      { children }
    </ProductsContext.Provider>
  );

};

export default ProductsState;