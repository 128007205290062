import './HomePage.css';
import Layout from '../../components/Layout/Layout';
import Home from '../../components/Home/Home';
import AdminRoute from '../../routes/AdminRoute';

const HomePage = () => {
  return (
    <Layout>
      <AdminRoute>
        <Home />
      </AdminRoute>
    </Layout>
  );
};

export default HomePage;