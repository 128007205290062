import './ExcelTable.css';
import { HotTable, HotColumn } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.css';


const ExcelTable = ({excelData, setUpdateButtonAppear, admin}) => { 
  
  registerAllModules();

  const PromotionRenderer = (props) => {
    const { value } = props;
    if (value) {
      return (
        <span>&#10004;</span>
      );
    }
    return (
      <span>&#10007;</span>
    );
  };

  return (
      <div className='rounded shadow-style bg-light p-2 my-5'>
        <HotTable
          afterDocumentKeyDown={() => setUpdateButtonAppear(true)}
          afterAutofill={() => setUpdateButtonAppear(true)}
          data={excelData} 
          licenseKey='non-commercial-and-evaluation'
          colHeaders={true}
          rowHeaders={true}
          columnSorting={true}
          contextMenu={["undo", "redo", "copy"]}
          autoWrapRow={true}
          autoRowSize={false}
          autoColumnSize={false}
          className="htCenter"
          readOnly={true}
        >
          <HotColumn data="weightRank" title='Peso' width="40px" readOnly={false} className="htCenter" />
          <HotColumn data="productName" title='Producto' width="175px" readOnly={!admin} className="htLeft" />
          <HotColumn data="image" title='URL imagen' readOnly={!admin} width="175px" className="htLeft" />
          <HotColumn data="equivalent" title='Equivalente' readOnly={false} width="160px" className="htLeft" />
          <HotColumn data="inStock" title='Stock' readOnly={true} width="50px" className="htCenter" />
          <HotColumn data="unit" title='Un.' width="40px" type='dropdown' source={["K", "U"]} readOnly={!admin} />
          <HotColumn data="minUnit" title='Un. Mín.' type='numeric' numericFormat={{pattern: "0.00"}} width="60px" readOnly={false} />
          <HotColumn data="delta" title='Incr.' type='numeric' numericFormat={{pattern: "0.00"}} width="60px" readOnly={false} />
          <HotColumn data="flag2" title='Bandera 2' type='numeric' numericFormat={{pattern: "0.0"}} width="70px" readOnly={false} />
          <HotColumn data="flag2Warn" title='<B2' width="40px" readOnly={true}>
            <PromotionRenderer hot-renderer />
          </HotColumn>
          <HotColumn data="flag1" title='Bandera 1' type='numeric' numericFormat={{pattern: "0.0"}} width="70px" readOnly={false} />
          <HotColumn data="flag1Warn" title='<B1' width="40px" readOnly={true}>
            <PromotionRenderer hot-renderer />
          </HotColumn>
          <HotColumn data="season" title='P. de Temp.' type="checkbox" width="70px" readOnly={false} />
          <HotColumn data="showItem" title='Mostrar' type="checkbox" width="60px" readOnly={false} />
        </HotTable>
      </div>
  );
};

export default ExcelTable;