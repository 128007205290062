import './UserOrdersPage.css';
import Layout from '../../components/Layout/Layout';
import AdminRoute from '../../routes/AdminRoute';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useContext, useEffect, useState } from 'react';
import stringSimilarity from 'string-similarity';
import axiosClient from '../../settings/axiosClient';
import ProductsContext from '../../context/Products/ProductsContext';
import { Button, Spinner } from 'react-bootstrap';
import OrderTable from '../../components/OrderTable/OrderTable';
import PopUp from '../../components/PopUp/PopUp';

const UserOrdersPage = () => {
  const [searchValue, setSearchValue] = useState('');
  const [spinner, setSpinner] = useState(false);
  const [allOrders, setAllOrders] = useState([]);
  const [showedOrders, setShowedOrders] = useState([]);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({});
  const { products, getAllProducts } = useContext(ProductsContext);
  const [bestSuggestion, setBestSuggestion] = useState([]);
  const [usersWithOrders, setUsersWithOrders] = useState([]);
  const [errorPopUp, setErrorPopUp] = useState(false);


  const handleChange = (e) => {
    setShowedOrders([]);
    setSearchValue(e.target.value);
    const filter = stringSimilarity.findBestMatch(e.target.value.toLowerCase(), usersWithOrders).ratings.filter(item => item.rating > 0.1).sort((a, b) => b.rating - a.rating);
    setBestSuggestion(filter.length !== 0 ? filter.map(item => item.target) : []);
  }

  const handleKeyDown = (e) => {
    if(e.keyCode === 9 && bestSuggestion.length !== 0){
      e.preventDefault();
      setSearchValue(bestSuggestion[0]);
      setBestSuggestion([]);
    }
    if(e.keyCode === 13){
      let orders = []
      if(usersWithOrders.includes(searchValue)){
        orders = allOrders.filter(order => order.owner === searchValue);
      }else if(!usersWithOrders.includes(searchValue) && bestSuggestion.length !== 0){
        setSearchValue(bestSuggestion[0]);
        orders = allOrders.filter(order => order.owner === bestSuggestion[0]);
      }
      setBestSuggestion([]);
      setShowedOrders(orders);
      e.target.blur();
    }
  }

  const handleClick = () => {
    let orders = []
    if(usersWithOrders.includes(searchValue)){
      orders = allOrders.filter(order => order.owner === searchValue);
    }else if(!usersWithOrders.includes(searchValue) && bestSuggestion.length !== 0){
      setSearchValue(bestSuggestion[0]);
      orders = allOrders.filter(order => order.owner === bestSuggestion[0]);
    }
    setBestSuggestion([]);
    setShowedOrders(orders);
  }

  const getOrders = async () => {
    if(spinner) return;
    setSpinner(true);
    try {
      const response = await axiosClient.get("/orders");
      if(response.status === 200) {
        const allOrdersComming = response.data.reverse();
        setAllOrders(allOrdersComming);
        setSuccess(true);
      }
    } catch (error) {
      setErrors({server: 'Error en el servidor, intente nuevamente.'});
    }
  }

  const getProductsFunction = async () => {
    const getProductsErrors = await getAllProducts();
    if(Object.keys(getProductsErrors). length !== 0) return setErrors(getProductsErrors);
    setSuccess(true);
  }

  const errorPopUpFunction = () => {
    setErrorPopUp(false);
    setErrors({});
  }

  useEffect(() => {
    if(products.length === 0) getProductsFunction();
    if(allOrders.length === 0) getOrders();
  }, [])

  useEffect(() => {
    if(allOrders.length !== 0){
      const nonRepeatedUsers  = [...new Set(allOrders.map(item => item.owner))];
      setUsersWithOrders(nonRepeatedUsers);
    }
  }, [allOrders])

  useEffect(() => {
    if(Object.keys(errors).length !== 0){
      setTimeout(() => {
        setSpinner(false);
        setErrorPopUp(true);
      }, 1000);
    }
  }, [errors])

  useEffect(() => {
    if(success){
      setSuccess(false);
      setSpinner(false);
    }
  }, [success])

  return (
    <Layout>
      <AdminRoute>
        {
          spinner ? <div className="form-spinner"><Spinner /></div> : null
        }
        <div className='userOrders-style pt-3'>
          <div className="input-group user-order-input">
            <span className="input-group-text"><FontAwesomeIcon icon={faSearch} /></span>
            <input type="text" tabIndex="-1" className="form-control" placeholder="Buscar ordenes por usuario" onChange={handleChange} value={searchValue} onKeyDown={handleKeyDown} />
            <Button variant='outline-secondary' onClick={handleClick}>Buscar</Button>
          </div>
          {
            bestSuggestion.length !== 0 && (
              <div className='w-100 user-order-input'><small className='text-muted'><span className='text-success'>{bestSuggestion[0]}</span> <span className='fst-italic'>"presione tab para elegir la sugerencia"</span><span>, {bestSuggestion.slice(1).length !== 0 ? `[${bestSuggestion.slice(1).join(', ')}]` : null}</span></small></div>
            )
          }

          <div className='ordersBox-style m-0 d-flex flex-wrap'>
            {
              showedOrders?.map((order, index) => (
                <OrderTable products={products} order={order} key={index} btnActive="all" />
              ))
            }
          </div>
        </div>
        <PopUp popUp={errorPopUp} setPopUp={setErrorPopUp} popUpTitle={"ERROR"} popUpText={Object.values(errors).join(', ')} redBorder={true} popUpBtnFunction={errorPopUpFunction} popUpBtnText={"OK"} noWayOfClose={true} />
      </AdminRoute>
    </Layout>
  );
};

export default UserOrdersPage;