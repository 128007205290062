import { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import AdminRoute from '../../routes/AdminRoute';
import axiosClient from '../../settings/axiosClient';
import './MessagePage.css';
import { Button, Form, Spinner, Table } from 'react-bootstrap';
import { firstUpperCase } from '../../helpers/firstUpperCase';
import PopUp from '../../components/PopUp/PopUp';

const MessagePage = () => {
  const [users, setUsers] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [message, setMessage] = useState('Hola {user}, ');
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [errorPopUp, setErrorPopUp] = useState(false);
  const [successPopUp, setSuccessPopUp] = useState(false);
  const [successMessages, setSuccessMessages] = useState([]);
  const [successMessage, setSuccessMessage] = useState(false);

  const bringUserData = async () => {
    setSpinner(true);
    try {
      const response = await axiosClient.get('/users/usersemail');
      if(response.status === 200){
        setUsers(response.data.filter(user => user.adminKey === undefined).map(user => ({...user, userChecked: false})));
      }
      const ordersResponse = await axiosClient.get('/orders/voucher');
      if (ordersResponse.status === 200) {
        setAllOrders(ordersResponse.data);
      }
      setSuccess(true);
    } catch (error) {
      setErrors({servidor: 'Error en el servidor. Intente nuevamente.'});
    }
  }

  const handleChange = (e, userEmail) => {
    if(e.target.name === "userChecked"){
      const data = users.concat([]);
      const index = data.findIndex(item => item.userEmail === userEmail);
      data[index].userChecked = e.target.checked;
      setUsers(data);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSpinner(true);
    let serverError = [];
    let successMessagesSup = [];
    const usersToSend = users.filter(user => user.userPhone && user.userChecked === true);
    for(let i = 0; i < usersToSend.length; i++){
      const messageUpdated = message.replace('{user}', firstUpperCase(usersToSend[i].userShortName));
      const dataDB = {
        userPhone: usersToSend[i].userPhone,
        message: messageUpdated
      }
      try {
        const response = await axiosClient.post('/message', dataDB);
        if(response.status === 200){
          successMessagesSup.push(usersToSend[i].userEmail);
        }
      } catch (error) {
        serverError.push(usersToSend[i].userEmail);
      }
    }
    if(serverError.length !== 0){
      setErrors({servidor: `Error en el servidor. Intente nuevamente. No se pudo enviar el mensaje a: ${serverError.join(', ')}`});
    }else{
      setSuccessMessages(successMessagesSup);
      setSuccessMessage(true);
      setUsers(users.map(user => ({...user, userChecked: false})));
      setMessage('Hola {user}, ');
    };
  }

  const popUpBtnFunction = () => {
    setErrorPopUp(false);
    setErrors({});
  }

  const successPopUpFunction = () => {
    setSuccessPopUp(false);
    setSuccessMessages([]);
  }

  useEffect(() => {
    if(users.length === 0 || allOrders.length === 0) bringUserData();
  }, [])

  useEffect(() => {
    if(Object.keys(errors).length !== 0){
      setSpinner(false);
      setErrorPopUp(true);
    }
  }, [errors])

  useEffect(() => {
    if(success){
      setSpinner(false);
      setSuccess(false);
    }
  }, [success])

  useEffect(() => {
    if(successMessage){
      setSpinner(false);
      setSuccessMessage(false);
      setSuccessPopUp(true);
    }
  }, [successMessage])

  return (
    <Layout>
      <AdminRoute>
        <div className='messagePage-style'>
          {
            spinner ? <div className="form-spinner"><Spinner /></div> : null
          }
        <Table striped bordered hover className='voucherFilteredTable-style'>
          <thead>
            <tr>
              <th className='text-center'></th>
              <th className='text-center'>#</th>
              <th className='text-center'>Usuario</th>
              <th className='text-center'>Pedidos</th>
              <th className='text-center'>Último Pedido</th>
              <th className='text-center'>$$</th>
            </tr>
          </thead>
          <tbody>
            {users?.map((userData, index) => (
              <tr key={index}>
                <td className='text-center'><input type="checkbox" id={`userChecked-${index}`} name='userChecked' onChange={(e) => handleChange(e, userData.userEmail)} checked={userData.userChecked} /> </td>
                <td>{index+1}</td>
                <td>{userData.userEmail}</td>
                <td className='text-center'>{allOrders.filter(order => order.owner === userData.userEmail).length}</td>
                <td className='text-center'>{allOrders.filter(order => order.owner === userData.userEmail).length !== 0 ? allOrders.filter(order => order.owner === userData.userEmail)[allOrders.filter(order => order.owner === userData.userEmail).length-1].deliveryDate : "-"}</td>
                <td className='text-center text-success fw-bold'>{allOrders.filter(order => order.owner === userData.userEmail).length !== 0 ? new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(Number(allOrders.filter(order => order.owner === userData.userEmail).map(item => item.totalPrice).reduce((a, b) => a+b, 0))) : "-"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className='px-5 w-100'>
          <Form className='w-100' onSubmit={handleSubmit}>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>Redactar mensaje</Form.Label>
              <Form.Control as="textarea" rows={3} onChange={(e) => setMessage(e.target.value)} value={message} />
            </Form.Group>
            <div className="mb-3">[{message.length}/400]</div>
          
            <Button variant="primary" type="submit" className='mb-5' disabled={users.filter(user => user.userPhone && user.userChecked === true).length === 0}>Enviar Mensaje</Button>
          </Form>
        </div>
        </div>
        <PopUp popUp={successPopUp} greenBorder={true} setPopUp={setSuccessPopUp} popUpTitle={"Mensajes Enviados"} popUpText={`Mensaje enviado a: ${successMessages.join(', ')}`} popUpBtnFunction={successPopUpFunction} popUpBtnText={"OK"} noWayOfClose={true} />
        <PopUp popUp={errorPopUp} setPopUp={setErrorPopUp} popUpTitle={"ERROR"} popUpText={Object.values(errors).join(', ')} redBorder={true} popUpBtnFunction={popUpBtnFunction} popUpBtnText={"OK"} noWayOfClose={true} />
      </AdminRoute>
    </Layout>
  );
};

export default MessagePage;