import { Table } from 'react-bootstrap';
import './VoucherTable.css';

const VoucherTable = ({ vouchers }) => {
  return (
    <Table striped bordered hover className='voucherTable-style'>
      <thead className='border border-dark'>
        <tr>
          <th className='text-center'>#</th>
          <th className='text-center'>Usuario</th>
          <th className='text-center'>Descuento</th>
          <th className='text-center'>Validez</th>
          <th className='text-center'>Uso</th>
        </tr>
      </thead>
      <tbody>
        {vouchers?.map((voucher, index) => (
          <tr key={index}>
            <td className={voucher.valid ? "bg-valid" : "bg-notvalid"}>{vouchers.length - index}</td>
            <td className={voucher.valid ? "bg-valid" : "bg-notvalid"}>{voucher.voucherOwner}</td>
            <td className={voucher.valid ? "bg-valid" : "bg-notvalid"}>{voucher.discountType === '$' ? `${new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(Number(voucher.voucherDiscount))}` : `${Number(voucher.voucherDiscount).toFixed(2)}${voucher.discountType}`}</td>
            <td className={voucher.valid ? "bg-valid" : "bg-notvalid"}>{voucher.validDate}</td>
            <td className={voucher.valid ? "bg-valid" : "bg-notvalid"}>{voucher.voucherUsed ? "Usado" : "Pendiente"}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default VoucherTable;